// src/AppContext.js

import { createContext, useContext, useState, useEffect, useRef } from "react";
import { format_course_data_source, useRequestWithNavigate, sleep, groupByTerm, unformatProfileCourses } from "../utils";
import default_conversation from "./default_conversation.json"
import { message } from "antd";

// Create a context
const AppContext = createContext();


// Create a provider component
export const AppProvider = ({ children, target_user, admin = false }) => {
    const [currentUserInfo, setCurrentUserInfo] = useState({
        student_number: null,
        name: "",
        email: "",
    });
    
    const request = useRequestWithNavigate();

    const [messageApi, messageContextHolder] = message.useMessage();

    const [allProfiles, setAllProfiles] = useState([]);
    const [activeProfile, setActiveProfile] = useState({});
    
    const [queryCourses, setQueryCourses] = useState([]);

    // TODO: Add a data layer to this, currently there is only display layer, and the AI can't operate on that, it's going to go off sync contantly
    const [formattedCourseData, setFormattedCourseData] = useState([]);
    const scheduleData = useRef([]);

    const setScheduleData = (data) => {
        scheduleData.current = data;
    }

    const [courseAddModalOpen, setCourseAddModalOpen] = useState(false);
    const [courseAddModalInfo, setCourseAddModalInfo] = useState({});

    const [courseInfoModalOpen, setCourseInfoModalOpen] = useState(false);
    const [currentCourseDetails, setCurrentCourseDetails] = useState({
        name: "Operating Systems",
        code: "ECE344H1",
        description:
            "Operating system structures, concurrency, synchronization, deadlock, CPU scheduling, memory management, file systems. The laboratory exercises will require implementation of part of an operating system.",
        // This could be ECE244H1 and/or ECE243H1, need a way to distinguish
        prerequisites: ["ECE244H1", "ECE243H1"],
        corequisites: [],
        exclusions: ["ECE353H1 S"],

        credit: 0.5,

        // 1, 2, 3, 4, 5, 6, 7(Science and Math)
        area: 6,

        // Kernel, Depth, HSS, CS, Free, None
        type: "Kernel",
        fall: true,
        winter: true,
        offered: "2022-2023",
        delivery: [3, 0, 30],
        au_dist: [0, 0, 0, 60, 40],
        ceab: [0, 0, 0, 32, 21.4],

        // Not Taken, Passed, Failed, In Progress, Planned
        status: "Not Taken",
    });

    const [chatHistory, setChatHistory] = useState(default_conversation);

    // Draggable Course Card State
    const draggingCard = useRef(null);

    const showServerSaveMessage = useRef(false);

    const UpdateProfileLoadingMessage = async (newProfile) => {
        // Open a loading message

        if(showServerSaveMessage.current){
            messageApi.open({
                key: "updateSave",
                type: 'loading',
                content: 'Saving updated profile to server...',
            });
        }

        await request('update_profile', {
            profile: newProfile
        }).then((response)=>{
            console.log("Server responded:", response);
            if(showServerSaveMessage.current){
                messageApi.open({
                    key: "updateSave",
                    type: 'success',
                    content: 'Updated profile saved to server successfully!',
                    duration: 2,
                });
            }
        }).catch((error)=>{
            console.error('Server responded with error:', error)
            if(showServerSaveMessage.current){
                messageApi.open({
                    key: "updateSave",
                    type: 'error',
                    content: 'Something went wrong, failed to save profile to server.',
                    duration: 2,
                });
            }
        })

        showServerSaveMessage.current = true;
    }

    const save_item_to_server = async (endpoint, payload, feedback=true, im="", sm="", fm="") => {
        console.log("save_item_to_server called", endpoint, payload, im, sm, fm);
        // debugger;
        if(feedback){
            messageApi.open({
                key: "updateSave",
                type: 'loading',
                content: im !== "" ? im : 'Saving to server...',
            });
        }

        return request(endpoint, payload, true, true).then((response) => {
            console.log("Item saved successfully to serverserver responded:", response);
            if(feedback){
                messageApi.open({
                    key: "updateSave",
                    type: 'success',
                    content: sm !== "" ? sm : 'Action saved to server successfully',
                    duration: 2,
                });
            }

            return response;
        }).catch((error) => {
            console.error("Error ouccured when saving item to server, error:", error);
            if(feedback){
                messageApi.open({
                    key: "updateSave",
                    type: 'error',
                    content: fm !== "" ? fm : 'Something went wrong, failed to save action to server',
                    duration: 2,
                });
            }
        })
    }

    async function formatUserInfo(data) {
        console.log("Formatuserinfo:", data);
        setCurrentUserInfo({
            name: data.name,
            email: data.email,
            utorid: data.utorid
        });

        let temp_all_profiles = [];
        let profile_set = false;
        let active_profile_key = null;

        for (const profile of data["profiles"]) {
            const cur_profile_requisites = await request('check_requisites', {key: profile.key}).then((data)=>{
                return {
                    'prerequistes': data,
                }
            })

            const profileInfo = {
                ...profile,
                courses: format_course_data_source(groupByTerm(profile.courses), cur_profile_requisites),
            };

            console.log("Profile:", profileInfo)

            temp_all_profiles.push(profileInfo)

            if (profile['is_default'] === 1) {
                profile_set = true;
                active_profile_key = profileInfo.key;
                setActiveProfile(profileInfo);
                setFormattedCourseData(profileInfo.courses);
                setScheduleData(profileInfo.courses);
            }
        }

        if(!profile_set){
            active_profile_key = temp_all_profiles[0].key;
            setActiveProfile(temp_all_profiles[0]);
            setFormattedCourseData(temp_all_profiles[0].courses);
            setScheduleData(temp_all_profiles[0].courses);
        }

        console.log("After formatting user data")
        console.log(temp_all_profiles)
        setAllProfiles(temp_all_profiles);

        return active_profile_key;
    }

    // Fetch user info and courses when the app loads
    useEffect(() => {
        if (!admin) {
            const load_dummy = false;

            if (load_dummy) {
                fetch("/dummy_data.json")
                    .then((response) => response.json())
                    .then((data) => {
                        // Update current user data after fetch
                        formatUserInfo(data);
                    })
                    .catch((error) => console.error("Error fetching the JSON data:", error));
            } else {
                request('get_user_info', {
                    utorid: target_user,
                }).then(data => {
                    let active_profile_key = formatUserInfo(data);
                    
                })
            }
        } else {
            const year = (new Date()).getFullYear();
            const month = (new Date()).getMonth();

            let term = 1;

            if(5 <= month && month < 9){
                term = 5;
            } else if (9 <= month){
                term = 9;
            }

            setFormattedCourseData([
                {
                    key: `${year}${term}`,
                    term_name: Number(`${year}${term}`),
                    term_courses: []
                }
            ]);
        }
    }, []);

    // Making sure that the courses are sync'd up with the active profile
    // I don't know if this is necessary, it's deleted for now to fix state conflicts
    // Update, I think this is actually needed, not really for the correct reason. Some functions operate on activeprofile.courses
    // while some other operate on formattedcoursedata, so we need to make sure they are in sync
    useEffect(()=>{
        // if(Object.keys(activeProfile) !== 0){
        //     let update = {
        //         ...activeProfile,
        //         courses: formattedCourseData
        //     }
        //     setActiveProfile(update);
        // }

        // console.log("Formatted course data changed:", formattedCourseData)

        // scheduleData.current = formattedCourseData;
    }, [formattedCourseData])

    return (
        <AppContext.Provider
            value={{
                currentUserInfo,

                formattedCourseData,
                setFormattedCourseData,

                currentCourseDetails,
                setCurrentCourseDetails,

                activeProfile,
                setActiveProfile,
                allProfiles,
                setAllProfiles,

                courseInfoModalOpen,
                setCourseInfoModalOpen,

                queryCourses,
                setQueryCourses,
                chatHistory,
                setChatHistory,

                messageContextHolder,

                draggingCard,
                showServerSaveMessage,

                courseAddModalOpen, 
                setCourseAddModalOpen,
                courseAddModalInfo, 
                setCourseAddModalInfo,

                save_item_to_server,

                scheduleData,
                setScheduleData,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to use context values
export const useAppContext = () => {
    return useContext(AppContext);
};
