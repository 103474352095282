import React, { useState, useEffect, Children } from "react";
import { Typography, Slider, Card, Input, Tabs, Collapse } from "antd";
import { compressData, decompressData } from "./utils";

const { Text } = Typography;

const TestCompression = () => {
    const [originalData, setOriginalData] = useState("");
    const [compressedData, setCompressedData] = useState("");
    const [decompressedData, setDecompressedData] = useState("");
    const [compressionTime, setCompressionTime] = useState(0);
    const [compressionRatio, setCompressionRatio] = useState(0);
    const [compressionQuality, setCompressionQuality] = useState(5);
    const [lgwin, setLgwin] = useState(22);

    useEffect(()=>{
        fetch("/dummy_data.json")
        .then((response) => response.json())
        .then((data) => {
            // Update current user data after fetch
            setOriginalData(JSON.stringify(data));
        })
        .catch((error) => console.error("Error fetching the JSON data:", error));

    }, [])

    useEffect(() => {
        handleCompression();
    }, [originalData, compressionQuality, lgwin]);

    const handleCompression = async () => {
        let header = {};

        if(originalData.length === 0){
            return;
        }

        const startTime = performance.now();
        const compressed = await compressData(originalData, header, {
            quality: compressionQuality,
            lgwin: lgwin,
        });
        const endTime = performance.now();

        setCompressedData(compressed);
        setCompressionTime(endTime - startTime);

        const decompressed = await decompressData(compressed, 'browser');
        setDecompressedData(decompressed);

        const ratio = (compressed.length / originalData.length) * 100;
        setCompressionRatio(ratio);
    };

    return (
        <Card title="Text Compression Test" style={{ width: '100%' }}>
            <div style={{ width: '50%' }}>
                <Slider min={0} max={11} defaultValue={compressionQuality} onChange={setCompressionQuality}></Slider>
                <Slider min={1} max={100} defaultValue={lgwin} onChange={setLgwin}></Slider>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <Text>Input Text:</Text>
                <Input.TextArea
                    rows={4}
                    value={originalData}
                    onChange={(e) => setOriginalData(e.target.value)}
                    style={{ marginTop: '8px' }}
                />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <Text>Compressed Data:</Text>
                <Input.TextArea
                    rows={4}
                    value={compressedData}
                    readOnly
                    style={{ marginTop: '8px' }}
                />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <Text>Decompressed Data:</Text>
                <Input.TextArea
                    rows={4}
                    value={decompressedData}
                    readOnly
                    style={{ marginTop: '8px' }}
                />
            </div>

            <div>
                <Text>Compression Time: {compressionTime.toFixed(2)} ms</Text>
                <br />
                <Text>Compression Ratio: {compressionRatio.toFixed(2)}%</Text>
            </div>
        </Card>
    )
}

const TestPage = () => {
    const test_items = [
        {
            key: "textcompression",
            label: "Compression",
            children: (
                <TestCompression/>
            )
        }
    ]

    return (
        <Collapse items={test_items}/>
    );

};

export default TestPage;
