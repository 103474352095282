import { message } from "antd";
import { useContext, useEffect } from "react";
import { useAppContext } from "../App/AppContext";
import { findCourseLocation, genericCourse2UserCourse, termToStr, termToChar, sleep, useRequestWithNavigate, dc, unformatProfileCourses, unformatCourses, format_course_data_source, groupByTerm } from "../utils";
import { CourseListContext } from "./CourseListContext";

const useCourseActions = () => {
    const { setFormattedCourseData, draggingCard, activeProfile, save_item_to_server, scheduleData, setScheduleData } = useAppContext();

    const { courseActionStep, courseActionHistory } = useContext(CourseListContext);

    const request = useRequestWithNavigate();

    const setCourseActionHistory = (newData) => {
        courseActionHistory.current = [...newData];
    };

    const updateProfileCourses = (newData) => {
        let temp_courses = [];

        for (const term of newData) {
            for (const course of term.term_courses) {
                temp_courses.push(genericCourse2UserCourse(course, Number(term.term_name)))
            }
        }

        const updatedProfile = {
            ...activeProfile,
            courses: temp_courses,
            is_default: 1
        };

        // Here, just send a profile update request
        // Might be better if there is a dedicated endpoint for updating courses
        save_item_to_server('update_profile', { profile: updatedProfile }, true, "", 'Term successfully deleted!', "");
    }

    const addActionToHistory = (action) => {
        console.log('addActionToHistory:', courseActionStep, courseActionHistory)
        if (courseActionStep.current !== courseActionHistory.current.length) {
            const newData = courseActionHistory.current.slice(0, courseActionStep.current);
            newData.push(action);
            setCourseActionHistory(newData);
        } else {
            setCourseActionHistory([...courseActionHistory.current, action]);
        }

        courseActionStep.current += 1;
    }

    const addTerm = (termName, termIndex, courses = [], record = true) => {
        const newData = [...scheduleData.current];

        newData.splice(termIndex, 0, {
            key: String(termName), term_name: Number(termName), term_courses: courses
        });

        setFormattedCourseData(newData);
        setScheduleData(newData);

        // If the user is adding a new term with some courses, then update the profile
        if (courses.length > 0) {
            updateProfileCourses(newData);
        }

        if (record) {
            const action = {
                operation: "addTerm",
                payload: {
                    term_name: Number(termName),
                    term_index: termIndex,
                    courses: courses
                }
            };

            addActionToHistory(action);
        }

        return [true, `Term (${termName}) added successfully.`]
    }

    const deleteTerm = (termIndex, record = true) => {
        const newData = [...scheduleData.current];

        const action = {
            operation: "deleteTerm",
            payload: {
                term_index: termIndex,
                term_info: newData[termIndex]
            }
        };

        newData.splice(termIndex, 1);
        setFormattedCourseData(newData);
        setScheduleData(newData);

        updateProfileCourses(newData);

        if (record) {
            addActionToHistory(action);
        }
    }

    const deleteCourse = (st, sc, record = true) => {
        const newData = [...scheduleData.current];

        const course_data = newData[st].term_courses[sc];

        newData[st].term_courses.splice(sc, 1);

        setFormattedCourseData(newData);
        setScheduleData(newData);

        const action = {
            operation: "delete",
            source: {
                row_index: st,
                course_index: sc,
                payload: course_data
            }
        };

        // save to server
        save_item_to_server('delete_profile_course', {
            key: activeProfile.key,
            course: genericCourse2UserCourse(course_data, course_data["term"])
        }).then((response)=>{
            // console.log("Add course response from server:", nonprocessable, status, violated);
            let requisites = {
                'prerequistes': response.data,
            }
            let updated = format_course_data_source(groupByTerm(unformatCourses(newData)), requisites);
            setFormattedCourseData(updated);
            setScheduleData(updated);
        });;

        if (record) {
            addActionToHistory(action);
        }
        draggingCard.current = null;
    }

    const moveCourse = (st, sc, tt, tc, record = true) => {
        const newData = [...scheduleData.current];
        const sourceCard = newData[st].term_courses[sc];

        // const [actionLegal, errorMessage] = checkCourseLegalBasic(sourceCard, newData[tt].term_name);
        // if(!actionLegal){
        //     message.error(errorMessage);
        //     draggingCard.current = null;
        //     return [false, errorMessage];
        // }

        // Add dragging course to location in new term
        newData[tt].term_courses.splice(tc, 0, sourceCard);

        // Delete the dragging course's old copy
        newData[st].term_courses.splice(sc + (st === tt && sc > tc), 1);
        sourceCard["term"] = newData[tt]["term_name"];

        sourceCard["code"] = `${sourceCard["code"].split(' ')[0]} ${termToChar(sourceCard["term"])}`

        setFormattedCourseData(newData);
        setScheduleData(newData);

        const action = {
            operation: "move",
            source: {
                row_index: st,
                course_index: sc,
            },
            target: {
                row_index: tt,
                course_index: tc,
            },
        };

        let newCourse = genericCourse2UserCourse(sourceCard, sourceCard["term"]);
        console.log("move course:", {
            key: activeProfile.key,
            course: newCourse,
            prev_term: String(newData[st].term_name)
        });

        // Save to server
        save_item_to_server('move_profile_course', {
            key: activeProfile.key,
            course: newCourse,
            prev_term: String(newData[st].term_name)
        }).then((response)=>{
            console.log("Response move", response);

            if(response.status !== 200){
                message.error("Failed to move course");
                return;
            }

            // console.log("Add course response from server:", nonprocessable, status, violated);
            let requisites = {
                'prerequistes': response.data,
            }
            let updated = format_course_data_source(groupByTerm(unformatCourses(newData)), requisites);
            setFormattedCourseData(updated);
            setScheduleData(updated);
        });

        if (record) {
            addActionToHistory(action);
        }
        draggingCard.current = null;
    };

    const swapCourse = (st, sc, tt, tc, record = true) => {
        const newData = dc(scheduleData.current);

        const sourceCard = newData[st].term_courses[sc];
        const targetCard = newData[tt].term_courses[tc];

        const sourceTerm = newData[st].term_name;
        const targetTerm = newData[tt].term_name;

        // Basic legal checks
        const [sourceLegal, sourceMessage] = checkCourseLegalBasic(sourceCard, newData[tt].term_name, false);
        const [targetLegal, targetMessage] = checkCourseLegalBasic(targetCard, newData[st].term_name, false);

        if (!sourceLegal || !targetLegal) {
            message.error(sourceMessage || targetMessage);
            draggingCard.current = null;
            return [false, `Source course move status: ${sourceLegal}, message: ${sourceMessage}\nTarget course move status: ${targetLegal}, message: ${targetMessage}`];
        }

        // Replace dragged card position with the dropped position
        targetCard.code = targetCard.code.split(' ')[0] + ' ' + termToChar(sourceTerm);
        sourceCard.code = sourceCard.code.split(' ')[0] + ' ' + termToChar(targetTerm);

        newData[st].term_courses[sc] = targetCard;
        newData[tt].term_courses[tc] = sourceCard;

        // Swap course term
        newData[st].term_courses[sc]["term"] = newData[st]["term_name"];
        newData[tt].term_courses[tc]["term"] = newData[tt]["term_name"];

        

        setFormattedCourseData(newData);
        setScheduleData(newData);

        const action = {
            operation: "swap",
            source: {
                row_index: st,
                course_index: sc,
            },
            target: {
                row_index: tt,
                course_index: tc,
            },
        };

        let newSourceCourse = genericCourse2UserCourse(newData[st].term_courses[sc], newData[st].term_courses[sc]["term"]);
        let newDestCourse = genericCourse2UserCourse(newData[tt].term_courses[tc], newData[tt].term_courses[tc]["term"]);
        
        // Save to server
        save_item_to_server('move_profile_course', {
            key: activeProfile.key,
            course: newSourceCourse,
            prev_term: Number(targetTerm)
        }, false);

        // // Save to server
        save_item_to_server('move_profile_course', {
            key: activeProfile.key,
            course: newDestCourse,
            prev_term: Number(sourceTerm)
        }, false).then((response)=>{
            // console.log("Add course response from server:", nonprocessable, status, violated);
            let requisites = {
                'prerequistes': response.data,
            }
            let updated = format_course_data_source(groupByTerm(unformatCourses(newData)), requisites);
            setFormattedCourseData(updated);
            setScheduleData(updated);
        })

        message.success("Course action saved to server");

        if (record) {
            addActionToHistory(action);
        }

        draggingCard.current = null;
    };

    const addCourse = (course_payload, target_row, target_col, record = true) => {
        const newData = dc(scheduleData.current);
        let new_course = dc(course_payload);
        let inferred_new_course = null;

        if(!new_course){
            return [false, "Course not found"];
        }

        console.log("Add new course to course table", new_course, target_col, newData);

        new_course["status"] = 1;
        new_course["term"] = newData[target_row]["term_name"];

        if (new_course['session'] === 'Y') {
            new_course["code"] = `${new_course["code"].split(' ')[0]} Y`

            inferred_new_course = dc(new_course);

            let theOtherTerm = new_course["term"];

            theOtherTerm += theOtherTerm % 10 === 9 ? 2 : -2;

            inferred_new_course["term"] = theOtherTerm;

            console.log("The other term:", theOtherTerm);
        } else {
            new_course["code"] = `${new_course["code"].split(' ')[0]} ${termToChar(new_course["term"])}`
        }

        const [legal, legal_check_message] = checkCourseLegalBasic(new_course, new_course['term']);
        if (!legal) {
            draggingCard.current = null;
            return [false, `Target course could not be added due to: ${legal_check_message}`];
        }

        if (inferred_new_course) {
            let inferred_target_row = 0;
            let term_found = false;

            for (; inferred_target_row < newData.length; inferred_target_row++) {
                let term = newData[inferred_target_row];

                if (term.term_name === inferred_new_course["term"]) {
                    term_found = true;
                    break
                }
            }

            if (!term_found) {
                let term_location = target_row;
                term_location += inferred_new_course["term"] % 10 === 9 ? -1 : 1;
                console.log("Term not found, creating new term.", inferred_new_course["term"], term_location);

                newData.splice(term_location, 0, {
                    key: String(inferred_new_course["term"]), term_name: Number(inferred_new_course["term"]), term_courses: [inferred_new_course]
                });

                const action = {
                    operation: "addTerm",
                    payload: {
                        term_name: Number(inferred_new_course["term"]),
                        term_index: term_location,
                        courses: [inferred_new_course]
                    }
                };

                addActionToHistory(action);
            } else {
                console.log("Term found:", inferred_target_row);
                let inferred_course_add_location = newData[inferred_target_row].term_courses.length;

                let [legal, legal_check_message] = checkCourseLegalBasic(inferred_new_course, inferred_new_course['term']);

                if (!legal) {
                    draggingCard.current = null;
                    return [false, `Inferred course could not be added due to: ${legal_check_message}`];
                }

                newData[inferred_target_row].term_courses.splice(inferred_course_add_location, 0, inferred_new_course);
            }
        }

        target_col = target_col ? target_col : newData[target_row].term_courses.length;
        newData[target_row].term_courses.splice(target_col, 0, new_course);

        setFormattedCourseData(newData);
        setScheduleData(newData);

        const action = {
            operation: "add",
            payload: new_course,
            target: {
                row_index: target_row,
                course_index: target_col,
            },
        };

        const userCourseVariant = genericCourse2UserCourse(new_course, new_course["term"]);
        console.log('Add course:', userCourseVariant);

        // send action to server
        save_item_to_server('add_profile_course', {
            key: activeProfile.key,
            course: userCourseVariant
        }).then((response)=>{
            // console.log("Add course response from server:", nonprocessable, status, violated);
            let requisites = {
                'prerequistes': response.data,
            }
            let updated = format_course_data_source(groupByTerm(unformatCourses(newData)), requisites);
            setFormattedCourseData(updated);
            setScheduleData(updated);
        })

        // save_item_to_server('update_profile', {
        //     profile: {...activeProfile, courses: [...unformatCourses(scheduleData.current), userCourseVariant]}
        // });

        if (record) {
            addActionToHistory(action);
        }

        draggingCard.current = null;

        return [true, "Course successfully added"];
    }

    const handleCourseCardDrop = (term, code) => {
        if (draggingCard.current) {
            var term_row_index = 0;
            var term_course_index = 0;

            const newData = [...scheduleData.current];

            console.log('on drop:', newData)

            // Extract the dragging card data
            let dragCardRow = null;
            let dragCardCourse = null;
            let draggedCard = null;
            console.log(draggingCard.current)
            if (draggingCard.current.new) {
                console.log("handle adding new course")
                draggedCard = draggingCard.current.payload;
            } else {
                dragCardRow = draggingCard.current.term_row_index;
                dragCardCourse = draggingCard.current.term_course_index;
                draggedCard = newData[dragCardRow].term_courses[dragCardCourse];
            }

            // Drop on empty row
            if (code === null) {
                term_row_index = findCourseLocation(scheduleData.current, term, null);

                console.log("Check legal", dc(draggedCard), term)
                const [actionLegal, errorMessage] = checkCourseLegalBasic(draggedCard, term);
                if (!actionLegal) {
                    draggingCard.current = null;
                    return errorMessage;
                }

                if (draggingCard.current.new) {
                    addCourse(draggedCard, term_row_index, newData[term_row_index].term_courses.length);
                } else {
                    console.log('drop on empty row', newData)
                    console.log(dragCardRow, dragCardCourse, term_row_index);
                    console.log(newData[term_row_index].term_courses.length);

                    moveCourse(dragCardRow, dragCardCourse, term_row_index, newData[term_row_index].term_courses.length);
                }
            } else {
                // Swap
                [term_row_index, term_course_index] = findCourseLocation(scheduleData.current, term, code);

                console.log("SWAPP")

                if (term_row_index === dragCardRow && term_course_index === dragCardCourse) {
                    console.log("NAHHHHH")
                    draggingCard.current = null;
                    return;
                }

                if (!checkCourseLegalBasic(draggedCard, term)) {
                    draggingCard.current = null;
                    return;
                }

                if (newData[term_row_index].term_courses[term_course_index]["code"].includes("PEY")) {
                    draggingCard.current = null;
                    return;
                }

                if (draggingCard.current.new) {
                    message.error("A new course can not be swapped with an existing course!")
                } else {
                    swapCourse(dragCardRow, dragCardCourse, term_row_index, term_course_index);
                }
            }
        }
    };

    const findCoursePosition = (rootRef, source_row_index, source_course_index) => {
        const tableDom = rootRef.current.querySelector(".ant-table-content");
        const termRowDom = tableDom.querySelectorAll("tbody tr")[source_row_index].querySelectorAll("td")[1].querySelector("div");
        var courseContainer = termRowDom.querySelectorAll("div .ant-col");

        // If source course index is -1, then it's the last one
        source_course_index = source_course_index === -1 ? courseContainer.length - 1 : source_course_index;
        courseContainer = courseContainer[source_course_index];
        const courseContainerPosition = courseContainer.getBoundingClientRect();

        return [courseContainer, courseContainerPosition];
    };

    const animateCourseCard = (courseContainer, startPos, endPos, x_off=0, y_off=0) => {
        // Clone the element
        const clone = courseContainer.cloneNode(true);
        const parent = courseContainer.parentNode;

        // Hide the original
        courseContainer.style.visibility = 'hidden';

        clone.style.position = 'absolute';
        clone.style.left = `${startPos.left - parent.getBoundingClientRect().left + parent.offsetLeft}px`;
        clone.style.zIndex = 1;
        parent.appendChild(clone);

        // debugger

        return new Promise((resolve) => {
            clone.addEventListener('transitionend', () => {
                // Slight delay before removing the clone to ensure the course data state update is complete
                setTimeout(() => {
                    parent.removeChild(clone);
                    // Show original after state update
                    resolve();
                }, 10); // 10ms delay
            });

            // Force reflow so the browser registers the initial position of the clone
            clone.getBoundingClientRect();
            clone.style.transition = 'transform 0.5s ease-in-out';
            clone.style.transform = `translate(${endPos.left - startPos.left + x_off}px, ${endPos.top - startPos.top + y_off}px)`;
        });
    };

    const singleCourseCardAnimation = (root_ref, source_row_index, source_course_index, target_row_index, target_course_index, zindex = 10) => {
        console.log("Moving course card:", source_row_index, source_course_index, target_row_index, target_course_index);

        const [courseContainer, courseContainerPosition] = findCoursePosition(root_ref, source_row_index, source_course_index);
        const [targetContainer, targetContainerPosition] = findCoursePosition(root_ref, target_row_index, target_course_index);

        let x_offset = 0;

        if(source_row_index !== target_row_index && target_course_index === -1){
            x_offset = targetContainerPosition.width;
        }

        let animation_response = animateCourseCard(courseContainer, courseContainerPosition, targetContainerPosition, x_offset);

        return [animation_response, courseContainer];
    };

    // TODO: Handle corner cases such as when the term is two rows
    const moveCourseCardAnimation = async (root_ref, source_row_index, source_course_index, target_row_index, target_course_index, additional=true) => {
        let animation_queue = [];
        let all_hidden_cards = [];

        if(additional){
            // Animate shifting the following courses on the same row
            for (const course of scheduleData.current[source_row_index].term_courses.slice(source_course_index + 1)) {
                // debugger
                const [row_index, course_index] = findCourseLocation(scheduleData.current, course.term, course.code);
                let [animation_response, course_container] = singleCourseCardAnimation(root_ref, row_index, course_index, row_index, course_index - 1);
                animation_queue.push(animation_response);
                all_hidden_cards.push(course_container);
            }

            if(source_row_index !== target_row_index){
                if(target_course_index !== -1){
                    target_course_index = Math.min(target_course_index, scheduleData.current[target_row_index].term_courses.length - 1);
                    // Animate shifting the following courses on the target row
                    for (const course of scheduleData.current[target_row_index].term_courses.slice(target_course_index)) {
                        console.log("Target row shifted", course, target_course_index)
                    }
                }
            }
        }

        // Animate the moving course card
        if (source_row_index === target_row_index) {
            // If it's the same row, move it to the end (or appropriate position)
            let [animation_response, course_container] = singleCourseCardAnimation(root_ref, source_row_index, source_course_index, target_row_index, scheduleData.current[source_row_index].term_courses.length - 1, 20)

            animation_queue.push(animation_response);
            all_hidden_cards.push(course_container);
        } else {
            // Different row
            let [animation_response, course_container] = singleCourseCardAnimation(root_ref, source_row_index, source_course_index, target_row_index, target_course_index, 20)
            animation_queue.push(animation_response);
            all_hidden_cards.push(course_container);
        }

        await Promise.all(animation_queue);

        console.log("All animations are done");

        return all_hidden_cards;
    };

    const swapCourseCardAnimation = async (root_ref, source_row_index, source_course_index, target_row_index, target_course_index) => {
        
        const [firstContainer, firstContainerPosition] = findCoursePosition(root_ref, source_row_index, source_course_index);
        const [secondContainer, secondContainerPosition] = findCoursePosition(root_ref, target_row_index, target_course_index);


        // Compute target translation
        let translateX = secondContainerPosition.left - firstContainerPosition.left;
        let translateY = secondContainerPosition.top - firstContainerPosition.top;

        const firstTransformData = `translate(${translateX}px, ${translateY}px)`;
        const secondTransformData = `translate(${-translateX}px, ${-translateY}px)`;
        const transitionData = `transform 0.5s ease-in-out`;

        // Prepare the element for transition
        firstContainer.style.willChange = "transform";
        firstContainer.style.zIndex = 15;

        secondContainer.style.willChange = "transform";
        secondContainer.style.zIndex = 15;

        // Force a reflow to ensure initial positions are calculated
        firstContainer.getBoundingClientRect(); // or courseContainer.offsetWidth
        secondContainer.getBoundingClientRect(); // or courseContainer.offsetWidth

        return new Promise((resolve) => {
            const onTransitionEndFirst = () => {
                firstContainer.removeEventListener('transitionend', onTransitionEndFirst);

                setTimeout(() => {
                    firstContainer.style.transition = "";
                    firstContainer.style.transform = "";
                    firstContainer.style.willChange = "";
    
                }, 10);

                resolve();
            };

            const onTransitionEndSecond = () => {
                secondContainer.removeEventListener('transitionend', onTransitionEndSecond);

                setTimeout(() => {
                    secondContainer.style.transition = "";
                    secondContainer.style.transform = "";
                    secondContainer.style.willChange = "";        
                }, 10);

                resolve();
            };


            firstContainer.addEventListener('transitionend', onTransitionEndFirst);
            secondContainer.addEventListener('transitionend', onTransitionEndSecond);

            // Trigger the transition
            firstContainer.style.transition = transitionData;
            firstContainer.style.transform = firstTransformData;

            secondContainer.style.transition = transitionData;
            secondContainer.style.transform = secondTransformData;
        });
    }

    const checkCourseLegalBasic = (course, target_term, show_message = true) => {
        // Basic legal checks without needing to query server
        const targetTermStr = termToStr(target_term);
        const currentTermStr = termToStr(course.term);

        console.log("checkCourseLegalBasic:", course);

        var error_message = null;

        // Some obivious ones
        if (course["code"].includes("PEY")) {
            error_message = `Illegal Course Action: PEY course is not movable`;
        } else if (course["status"] === 0) {
            error_message = "Illegal Course Action: Passed course is not movable";
        } else if (course["status"] === 2) {
            error_message = "Illegal Course Action: Failed course is not movable";
        }

        if (error_message !== null) {
            if (show_message) {
                message.error(error_message);
            }

            return [false, error_message];

        }

        console.log("Course term availability:", course['twin'], course[targetTermStr]);
        if (currentTermStr !== targetTermStr && !course['twin'] && !course[targetTermStr]) {
            if (show_message) {
                message.error(`Illegal Course Action: Target course is not offered in the ${targetTermStr} term`)
            }

            return [false, `Illegal Course Action: Target course is not offered in the ${targetTermStr} term`];
        }

        // Check if the target term already has the course
        for (const term of scheduleData.current) {
            if (term.term_name === target_term) {
                let total_credit = 0;
                for (const tc of term.term_courses) {
                    if (tc.code.split(' ')[0] === course.code.split(' ')[0]) {

                        if (show_message) {
                            message.error("Illegal Course Action: Target Course Already Exists");
                        }

                        return [false, "Illegal Course Action: Target Course Already Exists"];
                    }
                }

                // TODO: Add credit to user course modal
                if (term.term_courses.length >= 6) {
                    // if(total_credit > 3.0){

                    if (show_message) {
                        message.error("Illegal Course Action: Max Course Load Reached at the Target Term");
                    }

                    return [false, "Illegal Course Action: Max Course Load Reached at the Target Term"];
                }
            }
        }

        return [true, "The course is legal to be added to the target term"];
    }

    /**
     * Manipulates the course list based on the specified action.
     * 
     * @param {Object} root_ref - The root reference for animations.
     * @param {Object} action - The action to be performed.
     * @param {string} action.action - The type of action to perform. Possible values are "add", "swap", "move", "delete".
     * @param {Object} action.source - The source course details.
     * @param {string} action.source.code - The code of the source course.
     * @param {string} action.source.term - The term of the source course.
     * @param {Object} [action.target] - The destination course details (required for "add", "swap", and "move" actions).
     * @param {string} [action.target.code] - The code of the destination course (required for "swap").
     * @param {string} [action.target.term] - The term of the destination course (required for "swap" and "move").
     * @param {number} [action.target.row_index] - The row index of the destination course (required for "add").
     * @param {number} [action.target.course_index] - The course index of the destination course (required for "add").
     * 
     * @returns {Promise<Array>} A promise that resolves to an array where the first element is a boolean indicating success, and the second element is a message.
     * 
     * @example
     * // Add a new course
     * manipulateCourseList(root_ref, {
     *   action: "add",
     *   source: { code: "CSC108", term: 20229 },
     *   target: { row_index: 1, course_index: 2 }
     * });
     * 
     * @example
     * // Swap two courses
     * manipulateCourseList(root_ref, {
     *   action: "swap",
     *   source: { code: "CSC108", term: 20229 },
     *   target: { code: "MAT137", term: 20231 }
     * });
     * 
     * @example
     * // Move a course to another term
     * manipulateCourseList(root_ref, {
     *   action: "move",
     *   source: { code: "CSC108", term: 20229 },
     *   target: { term: 20231 }
     * });
     * 
     * @example
     * // Delete a course
     * manipulateCourseList(root_ref, {
     *   action: "delete",
     *   source: { code: "CSC108", term: 20229 }
     * });
     */
    const manipulateCourseList = async (root_ref, action) => {
        // There are a few possible operations:
        //      1. Add a brand new course to a term
        //      2. Swap two courses at two different terms
        //      3. Move one course from a term to another term
        //      4. Delete a course

        const action_name = action["action"].toLowerCase();

        if (action_name === "add") {
            addCourse(action["source"], action["target"]["row_index"], action["target"]["course_index"]);

            let action_result = "The course is successfully added to the term";

            return [true, action_result];
        } else if (action_name === "swap") {
            const target_code = action["target"]["code"];
            const target_term = action["target"]["term"];
            const [target_row_index, target_course_index] = findCourseLocation(scheduleData.current, target_term, target_code);

            const source_code = action["source"]["code"];
            const source_term = action["source"]["term"];
            const [source_row_index, source_index] = findCourseLocation(scheduleData.current, source_term, source_code);

            if (target_course_index === -1) {
                return [false, `The course ${action["target"]["code"]} was not found in the term ${action["target"]["term"]}`];
            }

            if (target_row_index === -1) {
                return [false, `The term term ${action["target"]["term"]} does not exist.`];
            }

            if (source_index === -1) {
                return [
                    false,
                    `The course ${action["source"]["code"]} was not found in the term ${action["source"]["term"]}`,
                ];
            }

            if (source_row_index === -1) {
                return [false, `The term term ${action["source"]["term"]} does not exist.`];
            }

            const newData = [...scheduleData.current];
            const target_card = newData[target_row_index].term_courses[target_course_index];

            await swapCourseCardAnimation(root_ref, source_row_index, source_index, target_row_index, target_course_index);

            newData[target_row_index].term_courses[target_course_index] = newData[source_row_index].term_courses[source_index];
            newData[source_row_index].term_courses[source_index] = target_card;

            newData[target_row_index].term_courses[target_course_index]["term"] = newData[target_row_index]["term_name"];
            newData[source_row_index].term_courses[source_index]["term"] = newData[source_row_index]["term_name"];

            setFormattedCourseData(newData);
            setScheduleData(newData);

            const action_result = `The course ${action["source"]["code"]} is successfully swapped with the course ${action["target"]["code"]}`;
            console.log(action_result);
            return [true, action_result];
        } else if (action_name === "move") {
            const target_term = action["target"]["term"];
            const target_row_index = findCourseLocation(scheduleData.current, target_term, null);

            const source_code = action["source"]["code"];
            const source_term = action["source"]["term"];
            const [source_row_index, source_index] = findCourseLocation(scheduleData.current, source_term, source_code);

            if (target_row_index === -1) {
                return [false, `The term term ${action["target"]["term"]} does not exist.`];
            }

            if (source_index === -1) {
                return [
                    false,
                    `The course ${action["source"]["code"]} was not found in the term ${action["source"]["term"]}`,
                ];
            }

            if (source_row_index === -1) {
                return [false, `The term term ${action["source"]["term"]} does not exist.`];
            }

            const newData = dc(scheduleData.current);

            console.log(source_row_index, source_index);

            newData[target_row_index].term_courses.push(newData[source_row_index].term_courses[source_index]);
            newData[target_row_index].term_courses.at(-1)["term"] = newData[target_row_index]["term_name"];

            newData[source_row_index].term_courses.splice(source_index, 1);

            console.log("newData:", newData);

            // Animation
            let all_hidden_cards = await moveCourseCardAnimation(root_ref, source_row_index, source_index, target_row_index, -1);

            setFormattedCourseData(newData);
            setScheduleData(newData);

            for (let container of all_hidden_cards) {
                container.style.visibility = 'visible';
            }

            const action_result = `The course ${action["source"]["code"]} is successfully moved to the term ${target_term}`;
            console.log(action_result);
            return [true, action_result];
        } else if (action_name === "delete") {
            const source_code = action["source"]["code"];
            const source_term = action["source"]["term"];
            const [source_row_index, source_index] = findCourseLocation(scheduleData.current, source_term, source_code);

            if (source_index === -1) {
                return [
                    false,
                    `The course ${action["source"]["code"]} was not found in the term ${action["source"]["term"]}`,
                ];
            }

            if (source_row_index === -1) {
                return [false, `The term term ${action["source"]["term"]} does not exist.`];
            }

            const newData = [...scheduleData.current];

            newData[source_row_index].term_courses.splice(source_index, 1);

            setFormattedCourseData(newData);
            setScheduleData(newData);

            const action_result = `The course ${action["source"]["code"]} is successfully deleted`;
            console.log(action_result);
            return [true, action_result];
        } else if (action_name === "add_term") {
            let term_name = Number(action['source']['term_name']);
            addTerm(term_name, action['target']['term_index'], action['source']['courses']);

            let action_result = "The term is successfully added";

            return [true, action_result];
        } else if (action_name === "delete_term") {
            deleteTerm(action['source']['term_index']);

            let action_result = "The term is successfully deleted";

            return [true, action_result];
        } else {
            console.log("The requested action is not unrecognized.");

            return [false, "The requested action is not unrecognized. The available actions are: [move, swap, delete, add]."];
        }
    };

    return { checkCourseLegalBasic, deleteTerm, addTerm, deleteCourse, addCourse, moveCourse, swapCourse, handleCourseCardDrop, manipulateCourseList };
};

export default useCourseActions;
