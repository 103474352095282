import { Input, Modal, Tabs, Tooltip, message, Divider, Select, Row, Col, Popover, Button, Switch, Collapse, Space, Flex } from "antd";
import { useAppContext } from "./App/AppContext";
import React, { useState, useEffect } from "react";
import { alphanumerical, termToChar } from "./utils";
import CourseSearchAutocomplete from "./DataVisualizer/CourseSearchAutocomplete";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { useRequestWithNavigate } from "./utils";
import GenericCourseCardSmall from "./DataVisualizer/GenericCourseCard";
import { CEABRequirementPanel } from "./DataVisualizer/SearchFilterPane";
import MultiCheckBox from "./DataVisualizer/MultiCheckBox";
import { FilterOutlined } from "@ant-design/icons";

const { Option } = Select;
const filterPaneWidth = 250;
const collapseWidth = 220;

const defaultSelectedTypes = ["kernel", "depth", "cs", "hss", "other", 'free', 'required'];

const CeabTypeModule = ({
    selectedTypes,
    setSelectedTypes,
    resetKey,
    setResetKey,
    ceabRow,
    setCeabRow
}) => {
    const filterTypeItemsData = [
        { key: "kernel", label: "Kernel" },
        { key: "depth", label: "Depth" },
        { key: "cs", label: "CS" },
        { key: "hss", label: "HSS" },
        { key: "other", label: "Other" },
        { key: "free", label: "Free" },
        { key: "required", label: "Required" },
    ];

    const detailedSettingItems = [
        {
            key: "typesSelect",
            label: "Searched Types",
            children: (
                <MultiCheckBox
                    data={filterTypeItemsData}
                    checkall
                    key={"ceabTypesSelectMultiSelectBox"}
                    value={selectedTypes}
                    onChange={setSelectedTypes}
                />
            ),
        },
        {
            key: "ceabFilter",
            label: "CEAB Filter",
            children: (
                <CEABRequirementPanel
                    resetKey={resetKey}
                    ceabRow={ceabRow}
                    setCeabRow={setCeabRow}
                />
            ),
        },
    ];

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Collapse
                    accordion
                    style={{
                        width: `${collapseWidth}px`,
                    }}
                    size="small"
                    items={detailedSettingItems}
                />
                <Button
                    onClick={() => {
                        setSelectedTypes(defaultSelectedTypes);
                        setResetKey((prevKey) => prevKey + 1);
                        setCeabRow([]);
                    }}
                    style={{
                        marginTop: "10px",
                    }}
                    type="primary"
                    danger
                >
                    Reset
                </Button>
            </div>
        </div>
    );
};

const SmallFilterPanel = ({ style = {}, children, setFilterData }) => {
    // Eng Filters
    const [selectedTypes, setSelectedTypes] = useState(defaultSelectedTypes);
    const [resetKey, setResetKey] = useState(0);
    const [ceabRow, setCeabRow] = useState([]);

    useEffect(()=>{
        const filterData = {
            type: selectedTypes,
            ceab: ceabRow.map((item)=>{
                const namesArray = ['MA', 'NS', 'CS', 'ES', 'ED'];
                return {
                    name: namesArray.indexOf(item.name),
                    operation: item.operation,
                    value: item.value
                }
            }),
        };

        setFilterData(filterData);
    }, [selectedTypes, ceabRow]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                ...style
            }}
        >
            <Popover
                trigger="click"
                placement="leftBottom"
                title={<span style={{ fontSize: "16px" }}>Filter Setting</span>}
                overlayStyle={{
                    width: `${filterPaneWidth}px`,
                }}
                content={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >

                        <CeabTypeModule
                            selectedTypes={selectedTypes}
                            setSelectedTypes={setSelectedTypes}
                            resetKey={resetKey}
                            setResetKey={setResetKey}
                            ceabRow={ceabRow}
                            setCeabRow={setCeabRow} 
                        />
                    </div>
                }
            >
                <Button
                    style={{
                        width: '32px',
                        height: '32px',
                        marginRight: "5px",
                    }}
                    icon={<FilterOutlined />}
                />
            </Popover>
        </div>
    );
};

const CourseSelectionPane = ({ courses }) => {
    console.log("courses", courses);
    const thisKey = alphanumerical();
    const [prefix, setPrefix] = useState("");
    const [filterData, setFilterData] = useState({});
    let yearSeperatedCourses = {
        "third": [],
        "fourth": [],
        "fifth": [],
        "other": []
    };

    let convertedTypes = [];

    if(filterData.type){
        for(let type of filterData.type){
            convertedTypes.push(type[0].toUpperCase());
        }
    }

    for (const course of courses) {
        const matchCourseYear = /\d{2,4}/;
        let matched = String(matchCourseYear.exec(course.code)[0]);
        if(!course.code.toLocaleLowerCase().includes(prefix.toLocaleLowerCase())){
            continue;
        }

        let ceabFailed = false;
        if(filterData.ceab){
            for(const ceab of filterData.ceab){
                const courseCeabVal = course.ceab[ceab.name];
                const curCeabValue = ceab.value * 100;

                if(ceab.operation === 'gt'){
                    console.log('CEAB:', courseCeabVal, curCeabValue)
                    if(courseCeabVal <= curCeabValue){
                        ceabFailed = true;
                        break;
                    }
                } else if (ceab.operation === 'lt'){
                    if(courseCeabVal >= curCeabValue){
                        ceabFailed = true;
                        break;
                    }
                } else {
                    if(courseCeabVal !== curCeabValue){
                        ceabFailed = true;
                        break;
                    }
                }
            }
        }

        if(ceabFailed || !convertedTypes.includes(course.type)){
            continue;
        }
        
        // console.log('filterData:', course, filterData);

        if (matched.length === 3) {
            if (matched[0] === '3') {
                yearSeperatedCourses.third.push(course)
            } else if (matched[0] === '4') {
                yearSeperatedCourses.fourth.push(course)
            } else if (matched[0] === '5') {
                yearSeperatedCourses.fifth.push(course)
            }
        } else {
            yearSeperatedCourses.other.push(course)
        }

    }

    console.log(yearSeperatedCourses);

    return (
        <div style={{
            width: '100%'
        }}>
            <Flex justify={'flex-end'}>
                <Space.Compact>
                    <SmallFilterPanel setFilterData={setFilterData}/>
                    <Input value={prefix} onChange={(e)=>{setPrefix(e.target.value)}} placeholder="Filter Course"></Input>
                </Space.Compact>
            </Flex>
            {
                yearSeperatedCourses.third.length !== 0 && (
                    <>
                        <Divider orientation="left" orientationMargin="0">Third Year Courses</Divider>
                        <Row gutter={[6, 6]}>
                            {
                                yearSeperatedCourses.third.map((item, ite) => {
                                    return (
                                        <Col span={6} key={`multiSelect-${thisKey}-${ite}`}>
                                            <GenericCourseCardSmall code={item.code} status={-1} course_info={item} draggable source="SecondaryCourseAdd" />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </>
                )
            }
            {
                yearSeperatedCourses.fourth.length !== 0 && (
                    <>
                        <Divider orientation="left" orientationMargin="0">Fourth Year Courses</Divider>
                        <Row gutter={[6, 6]}>
                            {
                                yearSeperatedCourses.fourth.map((item, ite) => {
                                    return (
                                        <Col span={6} key={`multiSelect-${thisKey}-${ite}`}>
                                            <GenericCourseCardSmall code={item.code} status={-1} course_info={item} draggable source="SecondaryCourseAdd" />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </>
                )
            }
            {
                yearSeperatedCourses.fifth.length !== 0 && (
                    <>
                        <Divider orientation="left" orientationMargin="0">Fifth Year Courses</Divider>
                        <Row gutter={[6, 6]}>
                            {
                                yearSeperatedCourses.fifth.map((item, ite) => {
                                    return (
                                        <Col span={6} key={`multiSelect-${thisKey}-${ite}`}>
                                            <GenericCourseCardSmall code={item.code} status={-1} course_info={item} draggable source="SecondaryCourseAdd" />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </>
                )
            }
            {
                yearSeperatedCourses.other.length !== 0 && (
                    <>
                        <Divider orientation="left" orientationMargin="0">Other Courses</Divider>
                        <Row gutter={[6, 6]}>
                            {
                                yearSeperatedCourses.other.map((item, ite) => {
                                    return (
                                        <Col span={6} key={`multiSelect-${thisKey}-${ite}`}>
                                            <GenericCourseCardSmall code={item.code} status={-1} course_info={item} draggable source="SecondaryCourseAdd" />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </>
                )
            }
        </div>
    )
}

const CourseAddModal = () => {
    const {
        courseAddModalOpen,
        setCourseAddModalOpen,
        courseAddModalInfo,
        formattedCourseData,
        setFormattedCourseData,
        draggingCard,
        setScheduleData
    } = useAppContext();
    const request = useRequestWithNavigate();

    const refreshInterval = 100;
    // const [prefix, setPrefix] = useState("");
    const [curTab, setCurTab] = useState('courseSelectionArea-1');
    const [courseTabs, setCourseTabs] = useState([
        {
            key: 'courseSelectionArea-1',
            label: (
                <Tooltip placement='right' title="Photonics & Semiconductor Physics">
                    Area 1
                </Tooltip>
            ),
            fetched: false,
            children: <LoadingOutlined style={{ fontSize: '24pt' }} />
        },
        {
            key: 'courseSelectionArea-2',
            label: (
                <Tooltip placement='right' title="Electromagnetics & Energy Systems">
                    Area 2
                </Tooltip>
            ),
            fetched: false,
            children: <LoadingOutlined style={{ fontSize: '24pt' }} />,
        },
        {
            key: 'courseSelectionArea-3',
            label: (
                <Tooltip placement='right' title="Analog & Digital Electronics">
                    Area 3
                </Tooltip>
            ),
            fetched: false,
            children: <LoadingOutlined style={{ fontSize: '24pt' }} />,
        },
        {
            key: 'courseSelectionArea-4',
            label: (
                <Tooltip placement='right' title="Control, Communications & Signal Processing">
                    Area 4
                </Tooltip>
            ),
            fetched: false,
            children: <LoadingOutlined style={{ fontSize: '24pt' }} />,
        },
        {
            key: 'courseSelectionArea-5',
            label: (
                <Tooltip placement='right' title="Computer Hardware & Computer Networks">
                    Area 5
                </Tooltip>
            ),
            fetched: false,
            children: <LoadingOutlined style={{ fontSize: '24pt' }} />,
        },
        {
            key: 'courseSelectionArea-6',
            label: (
                <Tooltip placement='right' title="Software">
                    Area 6
                </Tooltip>
            ),
            fetched: false,
            children: <LoadingOutlined style={{ fontSize: '24pt' }} />,
        },
        {
            key: 'courseSelectionArea-7',
            label: (
                <Tooltip placement='right' title="Science/Math Electives">
                    Area 7
                </Tooltip>
            ),
            fetched: false,
            children: <LoadingOutlined style={{ fontSize: '24pt' }} />,
        },
    ]);
    const [showDropArea, setShowDropArea] = useState(true);
    const [selectedCourses, setSelectedCourses] = useState([]);

    const handleOk = () => {
        const newData = [...formattedCourseData];

        console.log(selectedCourses)

        for (const term of newData) {
            if (term.term_name === courseAddModalInfo.term) {
                for(const selectedCourse of selectedCourses){
                    let tempCourse = {
                        ...selectedCourse
                    }

                    tempCourse['status'] = 1
                    let coureseExists = false;
                    for(const existingCourse of term.term_courses){
                        if(existingCourse.code.split(' ')[0] === selectedCourse.code.split(' ')[0]){
                            // message.error("Illegal Course Action: Selected course already exists")
                            coureseExists = true;
                        }
                    }

                    if(!coureseExists){
                        term.term_courses.push(tempCourse)
                    }
                }
                // term.term_courses = [...term.term_courses, ...selectedCourses];
                break;
            }
        }

        setSelectedCourses([]);
        
        setFormattedCourseData(newData);
        setScheduleData(newData);

        setCourseAddModalOpen(false);
    };

    const handleCancel = () => {
        setCourseAddModalOpen(false);
    };

    const onChange = (key) => {
        console.log(key);
    };

    const handleAddSelectedCourse = () => {
        console.log(draggingCard.current);
        if(selectedCourses.length >= 4){
            message.error("Max 4 courses at a time!");
            draggingCard.current = null;
            return;
        }
        let newSelectedCourses = [
            ...selectedCourses,
            draggingCard.current.payload
        ]

        let updatedSelectedCoursesArray = [
            ...new Set(newSelectedCourses)
        ]

        setSelectedCourses(updatedSelectedCoursesArray);

        if (updatedSelectedCoursesArray.length !== newSelectedCourses.length) {
            message.error("The selected course is already included!");
        }

        draggingCard.current = null;
    }

    function requestAndSetTab(area) {
        if (!courseTabs[Number(area) - 1]['fetched']) {
            console.log("Request for courses")
            request('search_area_courses', {
                area: String(area)
            }).then((data) => {
                if(!data){
                    return;
                }
                let newData = [...courseTabs];
                let termFilteredData = [];

                for(const searchedCourse of data){
                    let term_char = termToChar(courseAddModalInfo.term)
                    if(searchedCourse.code.split(' ').at(-1) === term_char){
                        console.log("searchedCourse", searchedCourse)
                        
                        termFilteredData.push(searchedCourse);   
                    }
                }

                newData[Number(area) - 1]['children'] = <CourseSelectionPane courses={termFilteredData}/>
                newData[Number(area) - 1]['fetched'] = true;
                setCourseTabs(newData)
                return data
            })
        }
    }

    // useEffect(() => {
    //     requestAndSetTab(1);

    //     const interval = setInterval(() => {
    //         if (draggingCard.current) {
    //             // console.log('Current ref value:', draggingCard.current);
    //             if (draggingCard.current.source === 'SecondaryCourseAdd') {
    //                 setShowDropArea(true);
    //                 console.log('Current ref value:', draggingCard.current);
    //             } else {
    //                 setShowDropArea(false);
    //             }
    //         }
    //     }, refreshInterval);

    //     return () => clearInterval(interval);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    useEffect(()=>{
        setShowDropArea(selectedCourses.length === 0);
    }, [selectedCourses])

    useEffect(() => {
        requestAndSetTab(Number(curTab.split('-').at(-1)));
    }, [curTab])

    return (
        <Modal
            title={<div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <span>Add A Course To {courseAddModalInfo.term}</span>
                {courseAddModalInfo.term % 10 === 1 && <img style={{marginLeft: '5px'}} width={24} height={24} src="https://cdn.icon-icons.com/icons2/3035/PNG/512/weather_snow_snowflake_winter_freeze_icon_189094.png"/>}
                {courseAddModalInfo.term % 10 === 9 && <img style={{marginLeft: '5px'}} width={24} height={24} src="https://cdn-icons-png.flaticon.com/512/12705/12705539.png"/>}
            </div>}
            open={courseAddModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={700}
            style={{
                top: "5%",
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '25%'
                }}>
                    {/* <CourseSearchAutocomplete style={{
                        height: '36px',
                        // marginTop: '16px',
                        width: '100%'
                    }} zindex={null} source="addCourseModal" /> */}

                    {/* <Input value={prefix} onChange={(e)=>{setPrefix(e.target.value)}} placeholder="Filter Course"></Input> */}
                    
                    <div
                        className={showDropArea ? "AddCourseDropArea" : ""}
                        style={{
                            height: '288px',
                            padding: '16px',
                            textAlign: 'center',
                            marginTop: '16px'
                        }}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={() => {
                            // console.log(draggingCourse.current);
                            // console.log(queryCourses);
                            handleAddSelectedCourse();
                        }}
                    >
                        {showDropArea ? "Drag Courses Here to Add Them to the Term" : (
                            selectedCourses.map((item, iterator) => (
                                <div key={`AddCourseSelection-${alphanumerical()}`}>
                                    <CloseOutlined
                                        className="closeButton"
                                        style={{
                                            left: '53%',
                                            top: '5px'
                                        }}
                                        onClick={() => {
                                            console.log("Delete course");
                                            const newData = [...selectedCourses];
                                            newData.splice(iterator, 1);
                                            setSelectedCourses(newData);
                                        }}
                                    />

                                    <div
                                        style={{
                                            width: "130px",
                                            height: "60px",
                                            marginRight: "5px",
                                            color: item["status"] === 0 ? "black" : "#f90",
                                            cursor: "default",
                                        }}
                                        className="CourseCard"
                                    >
                                        <span
                                            style={{
                                                fontSize: 14,
                                                fontFamily: "arial",
                                                textDecoration: "none",
                                                color: item["status"] === 0 ? "black" : "#f90",
                                                fontWeight: "bold",
                                            }}
                                            className="CourseCardFamily CourseCardCode"
                                        >
                                            {item["code"]}
                                        </span>

                                        <div
                                            style={{
                                                fontSize: "7pt",
                                                fontFamily: "arial",
                                                lineHeight: "1",
                                            }}
                                            className="CourseCardFamily CourseCardName"
                                        >
                                            {item["name"]}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>

                <Tabs style={{
                    width: '75%',
                    marginLeft: '16px'
                }}
                    tabPosition='right'
                    activeKey={curTab}
                    onTabClick={(e) => {
                        setCurTab(e);
                    }}
                    // defaultActiveKey="1"
                    items={courseTabs}
                    onChange={onChange}
                />
            </div>
        </Modal>
    );
};


export default CourseAddModal;