import ceab_default_data from "./ceab_data_default.json";
import "../Stylesheets/App.css";
import { Divider, List, Tabs, Typography, Dropdown, Table, Radio, Row } from "antd";
import { useAppContext } from "../App/AppContext";
import { alphanumerical, calculateCEABData, sleep, unformatCourses, unformatProfileCourses, useRequestWithNavigate } from "../utils";
import React, { useState, useRef, useEffect, useMemo, forwardRef } from "react";
import GenericCourseCardSmall from "./GenericCourseCard";
import { RightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const gotoAndFlashDelay = 100;

const CEABDetailsColumns = [
    {
        title: "Categories",
        dataIndex: "categories",
        key: "categories",
        align: "left",
        render: (item) => {
            return <p style={{ fontWeight: "bold" }}>{item}</p>;
        },
    },
    {
        title: "Minimum Requirement",
        dataIndex: "minimum",
        key: "minimum",
        align: "center",
    },
    {
        title: "Obtained",
        dataIndex: "obtained",
        key: "obtained",
        align: "center",
        render: (text, record, row) => {
            return <p style={{ color: text < record["minimum"] ? "red" : "green" }}>{text}</p>;
        },
    },
    {
        title: "Projected",
        dataIndex: "projected",
        key: "projected",
        align: "center",
        render: (text, record, row) => {
            return <p style={{ color: text < record["minimum"] ? "red" : "green" }}>{text}</p>;
        },
    },
    {
        title: "Outstanding",
        dataIndex: "outstanding",
        key: "outstanding",
        align: "center",
        render: (text, record, row) => {
            return <p style={{ color: text > 0 ? "red" : "green" }}>{text}</p>;
        },
    },
];

const firstSemRequiredCourses = ["APS100H1", "APS110H1", "APS111H1", "CIV100H1", "MAT186H1", "MAT188H1"];
const secondSemRequiredCourses = ["APS105H1", "APS112H1", "ECE110H1", "ECE191H1", "MAT187H1", "MIE100H1"];
const thirdSemRequiredCourses = ["ECE201H1", "ECE212H1", "ECE241H1", "ECE244H1", "MAT290H1", "MAT291H1"];
const fourthSemRequiredCourses = ["ECE216H1", "ECE221H1", "ECE231H1", "ECE243H1", "ECE297H1"];


const essentialColumns = [
    {
        title: "",
        dataIndex: "name",
        key: "name",
        align: "left",
        render: (item) => {
            return <p style={{ fontWeight: "bold" }}>{item}</p>;
        },
    },
    {
        title: "",
        dataIndex: "renderer",
        key: "courses",
        align: "center",
        render: (item, record, row) => {
            return item();
        },
    },
];

const GraduationChecker = () => {
    // There is some re-render issue, some are not updating correctly(graduation checklist)
    const { scheduleData, formattedCourseData, currentUserInfo, setActiveProfile, activeProfile, save_item_to_server } = useAppContext();

    // Group these statevariable update into 1 update, currently there are 5 seperate updates(5 re-renders)
    const [graduationCheckList, setGraduationCheckList] = useState([]);

    // New
    const [allCoursesKDList, setAllCoursesKDList] = useState([]);
    const [possibleGraduationScenarios, setPossibleGraduationScenarios] = useState([]);

    const [CEABDetailsData, setCEABDetailsData] = useState(ceab_default_data);
    const [lowerTabPanelActiveKey, setLowerTabPanelActiveKey] = useState("core_years_tab");
    const [graduationProgram, setGraduationProgram] = useState(activeProfile['ece']);
    const [essentialCoursesDict, setEssentialCoursesDict] = useState({
        EngineeringEconomic: [],
        Capstone: [],
        ScienceMath: [],
        TechnicalElective: [],
        HSS: [],
        CS: [],
        FreeElective: [],
    });
    const [CEABFlashRowKey, setCEABFlashRowKey] = useState(null);
    const [ENEFlashRowKey, setENEFlashRowKey] = useState(null);

    const curSplitPaneRoot = useRef(null);
    const ceabTableRef = useRef(null);

    function getActiveGraduationScenario(updatedPossibleGraduationScenarios) {
        let activeGraduationScenario = allCoursesKDList;

        if(updatedPossibleGraduationScenarios.length === 1){
            return updatedPossibleGraduationScenarios[0].courses;
        }

        for (const scenario of updatedPossibleGraduationScenarios) {
            if (scenario.result === graduationProgram) {
                activeGraduationScenario = scenario.courses;
            }
        }

        return activeGraduationScenario || [];
    }

    const calculateCoreCoursePass = () => {
        console.log("Recalculating core year pass status...");
        let return_array = [[], [], [], []];

        for (const code of firstSemRequiredCourses) {
            return_array[0].push({
                code: code,
                course_status: findCourse(code),
                flash: false,
            });
        }

        for (const code of secondSemRequiredCourses) {
            return_array[1].push({
                code: code,
                course_status: findCourse(code),
                flash: false,
            });
        }

        for (const code of thirdSemRequiredCourses) {
            return_array[2].push({
                code: code,
                course_status: findCourse(code),
                flash: false,
            });
        }

        for (const code of fourthSemRequiredCourses) {
            return_array[3].push({
                code: code,
                course_status: findCourse(code),
                flash: false,
            });
        }

        return return_array;
    };

    useEffect(() => {
        console.log('Activeprofile update:', activeProfile, graduationProgram);
    }, [activeProfile])

    const coreYearPassStatus = useMemo(() => {
        return calculateCoreCoursePass();
    }, [scheduleData.current]);

    useEffect(() => {
        curSplitPaneRoot.current = document.querySelector(".Pane.vertical.Pane1");
    }, []);

    async function delayedScrollDown() {
        await sleep(gotoAndFlashDelay);

        // + 1500 is arbitrary, just to make sure it scrolls to the bottom
        curSplitPaneRoot.current.scrollTo({
            top: window.scrollY + 1500,
            behavior: "smooth",
        });
    }

    function findCourse(code) {
        if (code === "") {
            return null;
        }

        code = code.toLowerCase();
        // console.log(scheduleData.current);
        for (const term of scheduleData.current) {
            for (const course of term.term_courses) {
                if (course.code.toLowerCase().includes(code)) {
                    return course;
                }
            }
        }

        return null;
    }

    const FlashCEABRow = async (key) => {
        // We add 200ms here to accommodate scroll time
        await sleep(gotoAndFlashDelay + 200);
        setCEABFlashRowKey(key);
        setTimeout(() => setCEABFlashRowKey(null), 1000 + gotoAndFlashDelay); // Remove the flash class
    };

    const CEABRowClassName = (record) => {
        return record.key === CEABFlashRowKey ? "flash-row" : "";
    };

    const FlashENE = async (key) => {
        // We add 200ms here to accommodate scroll time
        await sleep(gotoAndFlashDelay + 200);
        setENEFlashRowKey(key);
        setTimeout(() => setENEFlashRowKey(null), 1000 + gotoAndFlashDelay); // Remove the flash class
    };

    const ENERowClassName = (record) => {
        return record.key === ENEFlashRowKey ? "flash-row" : "";
    };

    const ExistingCourseCardSmall = ({ code }) => {
        const course_info = findCourse(code);

        if (course_info === null) {
            return <GenericCourseCardSmall code={code} term={null} clickable={false} status={2}></GenericCourseCardSmall>;
        }

        const status = course_info !== null ? course_info.status : 2;

        code = code.split(" ")[0];

        return <GenericCourseCardSmall code={code} term={course_info["term"]} course_info={course_info} status={status}></GenericCourseCardSmall>;
    };

    const essentialData = [
        {
            name: "Engineering Economics",
            key: "engineering_economics",
            courses: ["ECE472"],
            renderer: () => {
                if (essentialCoursesDict.EngineeringEconomic.length !== 0) {
                    return (
                        <Row>
                            <ExistingCourseCardSmall code={"ECE472"}></ExistingCourseCardSmall>
                        </Row>
                    );
                }

                return (
                    <Row>
                        <GenericCourseCardSmall code={"ECE472"} status={2} term={null}></GenericCourseCardSmall>
                    </Row>
                );
            },
        },
        {
            name: "Capstone",
            key: "capstone",
            courses: ["ECE496", "APS490", "BME498"],
            renderer: () => {
                if (essentialCoursesDict.Capstone.length !== 0) {
                    return (
                        <Row>
                            <ExistingCourseCardSmall code={essentialCoursesDict.Capstone[0]}></ExistingCourseCardSmall>
                        </Row>
                    );
                }

                return (
                    <Row style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <GenericCourseCardSmall code={"ECE496"} status={2} term={null}></GenericCourseCardSmall>
                        OR
                        <GenericCourseCardSmall code={"APS490"} status={2} term={null}></GenericCourseCardSmall>
                        OR
                        <GenericCourseCardSmall code={"BME498"} status={2} term={null}></GenericCourseCardSmall>
                    </Row>
                );
            },
        },
        {
            name: "Science/Math",
            key: "science/math",
            courses: [],
            renderer: () => {
                // Any area7 course would work
                if (essentialCoursesDict.ScienceMath.length !== 0) {
                    return (
                        <Row>
                            <ExistingCourseCardSmall code={essentialCoursesDict.ScienceMath[0]}></ExistingCourseCardSmall>
                        </Row>
                    );
                }

                return <span>Any Course in Area 7</span>;
            },
        },
        {
            name: "Technical Electives",
            key: "technical_electives",
            courses: ["CSC317H1", "ECE326H1", "ECE454H1"],
            renderer: () => {
                const numElectives = 3;
                let TEC = [...essentialCoursesDict.TechnicalElective];
                // let TEC = [essentialCoursesDict.TechnicalElective[0]];
                let missing = [];

                for (let ci = TEC.length; ci < numElectives; ci++) {
                    missing.push("Technical");
                }

                return (
                    <Row className="flexcenterspacedbetween">
                        {TEC.map((code) => (
                            <ExistingCourseCardSmall code={code} key={`TEC-${code}`}></ExistingCourseCardSmall>
                        ))}
                        {missing.map((code, ite) => (
                            <GenericCourseCardSmall code={code} term={null} clickable={false} status={2} key={`TEC-Missing-${ite}`}></GenericCourseCardSmall>
                        ))}
                    </Row>
                );
            },
        },
        {
            name: "HSS and CS",
            key: "HSS&CS",
            courses: ["CLA204H1", "LIN102H1", "JRE300H1", "JRE410H1"],
            renderer: () => {
                const num_hss = 2;
                const num_cs = 2;

                let hss_existing = [...essentialCoursesDict.HSS];
                let cs_existing = [...essentialCoursesDict.CS];

                let hss_missing = [];
                let cs_missing = [];

                for (let ci = hss_existing.length; ci < num_hss; ci++) {
                    hss_missing.push("HSS Course");
                }

                for (let ci = cs_existing.length; ci < num_cs; ci++) {
                    cs_missing.push("CS Course");
                }

                return (
                    <Row className="flexcenterspacedbetween">
                        {hss_existing.map((code, index) => (
                            <ExistingCourseCardSmall code={code} key={code + index} />
                        ))}

                        {hss_missing.map((code, index) => (
                            <GenericCourseCardSmall code={code} key={code + index} term={null} clickable={false} status={2} />
                        ))}

                        {cs_existing.map((code, index) => (
                            <ExistingCourseCardSmall code={code} key={code + index} />
                        ))}

                        {cs_missing.map((code, index) => (
                            <GenericCourseCardSmall code={code} key={code + index} term={null} clickable={false} status={2} />
                        ))}
                    </Row>
                );
            },
        },
        {
            name: "Free Elective",
            key: "free_elective",
            courses: ["CSC384H1"],
            renderer: () => {
                if (essentialCoursesDict.FreeElective.length !== 0) {
                    return (
                        <Row className="flexcenterspacedbetween">
                            <ExistingCourseCardSmall code={essentialCoursesDict.FreeElective[0]}></ExistingCourseCardSmall>
                        </Row>
                    );
                } else {
                    return (
                        <Row className="flexcenterspacedbetween">
                            <GenericCourseCardSmall code={"Any Elective"} status={2} term={null} clickable={false}></GenericCourseCardSmall>
                        </Row>
                    );
                }
            },
        },
    ];

    const KDColumns = [
        {
            title: "Area",
            dataIndex: "area",
            key: "area",
            align: "center",
            render: (item, record) => {
                return (
                    <p
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        {item}
                    </p>
                )
            },
        },
        {
            title: "Kernel",
            dataIndex: "kernel",
            key: "kernel",
            align: "center",
            render: (item) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <Row>
                            {item.map((code) => {
                                return <ExistingCourseCardSmall code={code} key={alphanumerical()}></ExistingCourseCardSmall>;
                            })}
                        </Row>
                    </div>
                );
            },
        },
        {
            title: "Depth",
            dataIndex: "depth",
            key: "depth",
            align: "center",
            render: (item, row, _) => {
                let limit = 2;

                // console.log(graduationProgram, limit, item.slice(0, limit));

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Row>
                            {item.slice(0, limit).map((code) => {
                                return <ExistingCourseCardSmall code={code} key={alphanumerical()}></ExistingCourseCardSmall>;
                            })}
                        </Row>
                    </div>
                );
            },
        },
    ];

    const canGraduate = !graduationCheckList.some((item) => !item["status"]);

    const RequiredCourseCardSmall = ({ sem_num }) => {
        return coreYearPassStatus[sem_num].map((found_status, code_id) => {
            let course_info = findCourse(found_status.code)

            if (found_status.course_status === null) {
                return (
                    <GenericCourseCardSmall
                        code={found_status.code}
                        status={2}
                        clickable={false}
                        course_info={course_info}
                        className={found_status.flash ? "flash-row" : ""}
                        key={alphanumerical()}
                        width={'100px'}
                    />
                );
            }

            return (
                <GenericCourseCardSmall
                    code={found_status.code}
                    status={found_status.course_status.status}
                    className={found_status.flash ? "flash-row" : ""}
                    clickable={true}
                    course_info={course_info}
                    key={alphanumerical()}
                    width={'100px'}
                />
            );
        });
    };

    const lowerTabsContent = [
        {
            label: `Core Years`,
            key: "core_years_tab",
            children: (
                <div className="lowerProgramRequirementWrapper" style={{ paddingRight: "24px" }}>
                    <div style={{ textAlign: "left" }}>
                        <Title level={3}>First Year</Title>
                    </div>
                    <Row>
                        <RequiredCourseCardSmall sem_num={0}></RequiredCourseCardSmall>
                    </Row>

                    <Row style={{ marginTop: "10px" }}>
                        <RequiredCourseCardSmall sem_num={1}></RequiredCourseCardSmall>
                    </Row>

                    <Divider />
                    <div style={{ textAlign: "left" }}>
                        <Title level={3}>Second Year</Title>
                    </div>
                    <Row>
                        <RequiredCourseCardSmall sem_num={2}></RequiredCourseCardSmall>
                    </Row>

                    <Row style={{ marginTop: "10px" }}>
                        <RequiredCourseCardSmall sem_num={3}></RequiredCourseCardSmall>
                    </Row>
                </div>
            ),
        },
        {
            label: `Kernel/Depth Courses`,
            key: "kernel_depth_courses",
            children: (function () {
                let activeGraduationScenario = allCoursesKDList || [];
                let EE_eligible = false;
                let CE_eligible = false;
                
                if(possibleGraduationScenarios.length === 1){
                    activeGraduationScenario = possibleGraduationScenarios[0].courses;
                    CE_eligible = possibleGraduationScenarios[0].result === 'C';
                    EE_eligible = possibleGraduationScenarios[0].result === 'E';
                } else {
                    for (const scenario of possibleGraduationScenarios) {
                        if (scenario.result === 'C') {
                            CE_eligible = true;
                        } else {
                            EE_eligible = true;
                        }
    
                        if (scenario.result === graduationProgram) {
                            activeGraduationScenario = scenario.courses;
                        }
                    }
                }

                console.log('activeGraduationScenario:', graduationProgram, allCoursesKDList, activeGraduationScenario, EE_eligible, CE_eligible);

                if(EE_eligible & !CE_eligible){
                    if(graduationProgram !== 'E'){
                        setGraduationProgram('E');
                    }
                } else if (CE_eligible && !EE_eligible){
                    if(graduationProgram !== 'C'){
                        setGraduationProgram('C');
                    }
                }

                return (
                    <>
                        {EE_eligible && CE_eligible ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <h4 level={4}>Your are elegible to graduate as both EE and CE:</h4>
                                <Radio.Group
                                    style={{ marginLeft: "5px" }}
                                    value={graduationProgram}
                                    onChange={(e) => {
                                        console.log("Update graduation program to:", e.target.value);
                                        // setActiveProfile((prev) => {
                                        //     return {
                                        //         ...prev,
                                        //         ece: e.target.value
                                        //     }
                                        // })
                                        setGraduationProgram(e.target.value);
                                    }}
                                >
                                    <Radio value={"E"}>Graduate as EE</Radio>
                                    <Radio value={"C"}>Graduate as CE</Radio>
                                </Radio.Group>
                            </div>
                        ) : EE_eligible ? (
                            <div>Your are currently graduating as: EE</div>
                        ) : CE_eligible ? (
                            <div>Your are currently graduating as: CE</div>
                        ) : (
                            <div style={{
                                color: 'red',
                                fontWeight: 'bold'
                            }}>Your don't currently satisfy any program </div>
                        )}

                        <Table
                            style={{ paddingRight: "24px" }}
                            columns={KDColumns}
                            dataSource={activeGraduationScenario}
                            showHeader={true}
                            pagination={false}
                            size="small"
                        />
                    </>
                );
            })(),
        },
        {
            // Currently the implementation is not correct
            label: `Electives and Essentials`,
            key: "additional_required_courses",
            children: (
                <Table
                    style={{ paddingRight: "24px" }}
                    columns={essentialColumns}
                    dataSource={essentialData}
                    showHeader={false}
                    pagination={false}
                    size="small"
                    rowClassName={ENERowClassName}
                />
            ),
        },
        {
            label: `CEAB Requirements`,
            key: "ceab_requirements_tab",
            children: (
                <div style={{ paddingRight: "24px" }}>
                    <Table
                        columns={CEABDetailsColumns}
                        dataSource={CEABDetailsData}
                        showHeader={true}
                        pagination={false}
                        size="small"
                        ref={ceabTableRef}
                        rowClassName={CEABRowClassName}
                    />
                </div>
            ),
        },
        {
            label: `Graduation Status`,
            key: "graduation_eligibility_tab",
            children: (
                <div style={{ paddingRight: "24px" }}>
                    <div style={{ textAlign: "left" }}>
                        <Title level={3}>PEY Requirement</Title>

                        {currentUserInfo && currentUserInfo["PEY"] ? (
                            <Text type="success" strong>
                                You meet the Practical Experience requirement.
                            </Text>
                        ) : (
                            <Text type="danger" strong>
                                You do NOT meet the Practical Experience requirement currently.
                            </Text>
                        )}
                    </div>
                    <Divider style={{}} />
                    <div style={{ textAlign: "left" }}>
                        <Title level={3}>Graduation Eligibility</Title>

                        <List
                            size="small"
                            dataSource={graduationCheckList}
                            renderItem={(item, index) => (
                                <List.Item>
                                    {item["details"] !== null ? (
                                        <Dropdown
                                            menu={{
                                                items: item["details"],
                                            }}
                                        >
                                            <Text type={item["status"] ? "success" : "danger"} strong>
                                                {" "}
                                                {index + 1}: {item["text"]}{" "}
                                            </Text>
                                        </Dropdown>
                                    ) : (
                                        <Text type={item["status"] ? "success" : "danger"} strong>
                                            {" "}
                                            {index + 1}: {item["text"]}{" "}
                                        </Text>
                                    )}
                                </List.Item>
                            )}
                        />

                        {/* <Divider/> */}

                        <div style={{ textAlign: "center" }}>
                            <Title level={4} type={canGraduate ? "success" : "danger"}>
                                {canGraduate ? "All graduation eligibility checks pass. You can graduate!" : "You can't graduate! Please check the graduation eligibility."}
                            </Title>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    const calculateGraduationScenario = () => {
        console.log("============== calculateGraduationScenario start ==============", scheduleData.current);
        if (scheduleData.current.length === 0) {
            return [[], []];
        }

        let KDMatrix = {};

        for (const term of scheduleData.current) {
            for (const course of term.term_courses) {
                if (!course.area || course.area === 'O') {
                    continue;
                }

                for (const singleAreaCode of course.area) {
                    // Initialize area in KD matrix
                    if (KDMatrix[singleAreaCode] === undefined) {
                        KDMatrix[singleAreaCode] = {
                            kernel: [],
                            depth: [],
                        };
                    }

                    if (course.status === 2) {
                        continue;
                    }

                    if (course.type === "K") {
                        // console.log("found kernel course:", course)
                        if (KDMatrix[singleAreaCode]["kernel"].length === 0) {
                            KDMatrix[singleAreaCode]["kernel"].push(course.code);
                        } else {
                            KDMatrix[singleAreaCode]["depth"].push(course.code);
                        }
                    } else if (course.type === "D") {
                        KDMatrix[singleAreaCode]["depth"].push(course.code);
                    }
                }
            }
        }

        function sortKDMatrix(inputMatrix) {
            let entries = Object.entries(inputMatrix);

            // Sort the array based on the value of 'ab'
            entries.sort(([, objA], [, objB]) => {
                return objB.depth.length - objA.depth.length;
            });

            let sortedKDMatrix = [];

            for (let [key, KD] of entries) {
                // Area 7 doesn't count
                if (key === "o" || key === "7") {
                    continue;
                }

                // If area does not have kernel then it does not count
                if (KD["kernel"].length === 0) {
                    continue;
                }

                // KD["depth"] = KD["depth"].slice(0, 2);

                sortedKDMatrix.push({
                    area: key,
                    ...KD,
                });
            }

            entries = Object.entries(sortedKDMatrix);

            // Sort the array based on the area
            entries.sort(([, objA], [, objB]) => {
                return objA.area - objB.area;
            });

            sortedKDMatrix = [];

            for (const [key, KD] of entries) {
                sortedKDMatrix.push({
                    ...KD,
                    key: `area-${KD.area}`,
                });
            }

            return sortedKDMatrix;
        }

        let all_graduation_scenarios = [
            {
                "program": "C",
                "requirements": [
                    { "area": "C", "kernel": 1, "depth": 2 },
                    { "area": "C", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 0 },
                    { "area": "E", "kernel": 1, "depth": 0 }
                ]
            },
            {
                "program": "C",
                "requirements": [
                    { "area": "C", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "C", "kernel": 1, "depth": 0 },
                    { "area": "E", "kernel": 1, "depth": 0 }
                ]
            },
            {
                "program": "E",
                "requirements": [
                    { "area": "C", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 0 },
                    { "area": "E", "kernel": 1, "depth": 0 }
                ]
            },
            {
                "program": "E",
                "requirements": [
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "C", "kernel": 1, "depth": 0 },
                    { "area": "C", "kernel": 1, "depth": 0 }
                ]
            },
            {
                "program": "E",
                "requirements": [
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "C", "kernel": 1, "depth": 0 },
                    { "area": "E", "kernel": 1, "depth": 0 }
                ]
            },
            {
                "program": "E",
                "requirements": [
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 2 },
                    { "area": "E", "kernel": 1, "depth": 0 },
                    { "area": "E", "kernel": 1, "depth": 0 }
                ]
            }
        ];

        let possible_graduation_scenarios = [];
        let CE_possible = false;
        let EE_possible = false;

        for (const scenario of all_graduation_scenarios) {
            let scenario_result = scenario.program;
            let requirements = scenario.requirements;
            let all_satisfied = true;
            let used_courses = [];

            let scenario_courses = [];

            let tempKDMatrix = { ...KDMatrix };

            for (const single_requirement of requirements) {
                let target_area = single_requirement.area;
                let num_kernel = single_requirement.kernel;
                let num_depth = single_requirement.depth;
                let requirement_result = null;
                let newTempKDMatrix = {};
                for (const [area, content] of Object.entries(tempKDMatrix)) {
                    if (area === '7') {
                        newTempKDMatrix[area] = content;
                        continue;
                    }

                    if ((target_area === 'C' && area < '5') || (target_area === 'E' && area >= '5')) {
                        newTempKDMatrix[area] = content;
                        continue;
                    }

                    if (requirement_result === null && content.kernel.length >= num_kernel && content.depth.length >= num_depth) {
                        // console.log("Found good area:", area, content);
                        // console.log(content.kernel.length, num_kernel, content.depth.length, num_depth)

                        if(content.depth.length === num_depth){
                            // In this case, we don't have wiggle room
                            requirement_result = {
                                'area': area,
                                'kernel': content.kernel.slice(0, num_kernel),
                                'depth': content.depth
                            };
                        } else {
                            // In this case, we have wiggle room, need to put courses that only belong to the target area first, then the rest
                            
                            let depth_courses_copy = [...content.depth];

                            console.log('content', content);
                            console.log('depth_courses_copy:', depth_courses_copy)

                            while(depth_courses_copy.length > num_depth){
                                let found = false;
                                // Find all area 7 courses and get rid of them
                                for(let i = 0; i < depth_courses_copy.length; i++){
                                    if(tempKDMatrix['7']?.depth.includes(depth_courses_copy[i])){
                                        found = true;
                                        depth_courses_copy.splice(i, 1);
                                        break
                                    }
                                }

                                // If we have not found any area 7 course, then break out
                                if(!found){
                                    break;
                                }
                            }

                            requirement_result = {
                                'area': area,
                                'kernel': content.kernel.slice(0, num_kernel),
                                'depth': depth_courses_copy.slice(0, num_depth)
                            };
                        }
                        
                    } else {
                        newTempKDMatrix[area] = content;
                    }
                }

                if (requirement_result === null) {
                    all_satisfied = false;
                    break;
                }

                tempKDMatrix = newTempKDMatrix;

                used_courses.push(...[...requirement_result.kernel, ...requirement_result.depth]);
                scenario_courses.push(requirement_result);
            }

            // console.log('used_courses:', used_courses);
            // console.log('scenario_courses:', scenario_courses);

            if (scenario_result === 'C' && CE_possible) {
                continue;
            }

            if (scenario_result === 'E' && EE_possible) {
                continue;
            }

            if (all_satisfied) {
                possible_graduation_scenarios.push({
                    'result': scenario_result,
                    'courses': sortKDMatrix(scenario_courses),
                    'requirements': requirements
                });

                if (scenario_result === 'C') {
                    CE_possible = true;
                } else {
                    EE_possible = true;
                }
            }
        }

        console.log('KDMatrix', KDMatrix);
        console.log("possible_graduation_scenarios", possible_graduation_scenarios);

        let sorted_KDMatrix = sortKDMatrix(KDMatrix);
        console.log('sorted_KDMatrix', sorted_KDMatrix);

        console.log("============== calculateGraduationScenario end ==============");

        return [sorted_KDMatrix, possible_graduation_scenarios];
    };

    const getGraduationCheckList = (updatedEssentialCoursesDict, updatedPossibleGraduationScenarios, updatedCeabData) => {
        if (scheduleData.current.length === 0) {
            return [];
        }
    
        console.log('Check graduation status.')
        const all_checks = [
            () => {
                const success_text = "All required core years courses were taken and passed.";
                const fail_text = "Some required core years courses were not taken or passed.";
    
                let failed_courses = [];
    
                let total_score = 0;
                let cur_score = 0;
    
                for (let sem_id = 0; sem_id < coreYearPassStatus.length; sem_id++) {
                    const semester = coreYearPassStatus[sem_id];
    
                    for (const found_status of semester) {
                        total_score += 1;
                        if (found_status.course_status === null) {
                            failed_courses.push([sem_id, found_status.code]);
                        } else if (found_status.course_status.status === 2) {
                            failed_courses.push([sem_id, found_status.code]);
                        } else {
                            cur_score += 1;
                        }
                    }
                }
    
                cur_score = cur_score / total_score * 2;
                total_score = 2;
    
                let success = failed_courses.length === 0;
                let details = [];
    
                if (!success) {
                    for (const [sem_id, code] of failed_courses) {
                        details.push({
                            key: `${alphanumerical()}`,
                            label: (
                                <span
                                    onClick={async () => {
                                        setLowerTabPanelActiveKey("core_years_tab");
    
                                        let newData = [...coreYearPassStatus];
    
                                        for (let i = 0; i < coreYearPassStatus.length; i++) {
                                            let sem_status = coreYearPassStatus[i];
                                            for (let j = 0; j < sem_status.length; j++) {
                                                let found_status = sem_status[j];
                                                if (found_status.code === code) {
                                                    delayedScrollDown();
                                                    newData[i][j]["flash"] = true;
                                                    await sleep(1100);
                                                    newData[i][j]["flash"] = false;
                                                }
                                            }
                                        }
                                    }}
                                >
                                    Term {sem_id + 1}: {code}
                                </span>
                            ),
                            icon: <RightOutlined></RightOutlined>,
                        });
                    }
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score];
            },
            () => {
                const success_text = "Breadth requirement is met.";
                const fail_text = "Not enough kernel courses are taken.";
    
                let activeGraduationScenario = getActiveGraduationScenario(updatedPossibleGraduationScenarios);
    
                let success = activeGraduationScenario.length >= 4;
                let details = [];
    
                const total_score = 4;
                let cur_score = 0;
    
                for (const area of activeGraduationScenario) {
                    if (area.kernel.length === 0) {
                        success = false;
                    } else {
                        cur_score += 1;
                    }
                }
    
                cur_score = Math.min(cur_score, total_score);
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("kernel_depth_courses");
                                }}
                            >
                                Go to Kernel/Depth Courses
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
            },
            () => {
                const success_text = "Depth requirement is met.";
                const fail_text = "Not enough depth courses are taken for at least 2 kernel areas.";
    
                let success = false;
                let details = [];
                let numFulfilledArea = 0;
    
                const total_score = 4;
                let cur_score = 0;
    
                let activeGraduationScenario = getActiveGraduationScenario(updatedPossibleGraduationScenarios);
    
                for (const area of activeGraduationScenario) {
                    if (area.kernel.length === 1 && area.depth.length === 2) {
                        numFulfilledArea++;
                    }
                }
    
                cur_score = Math.min(numFulfilledArea * 2, total_score);
                success = numFulfilledArea >= 2;
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("kernel_depth_courses");
                                }}
                            >
                                Go to Kernel/Depth Courses
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
            },
            () => {
                const success_text = "Capstone requirement is met.";
                const fail_text = "Capstone course is not taken.";
    
                // console.log(updatedEssentialCoursesDict)
                let success = updatedEssentialCoursesDict.Capstone.length === 1;
                let details = [];
                const total_score = 2;
                let cur_score = success ? total_score : 0;
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("additional_required_courses");
                                    delayedScrollDown();
                                    FlashENE('capstone');
                                }}
                            >
                                Go to Electives and Essentials: Capstone
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
            },
            () => {
                const success_text = "Math/Science requirement is met.";
                const fail_text = "At least one Math/Science course must be taken.";
    
                let success = updatedEssentialCoursesDict.ScienceMath.length >= 1;
                let details = [];
    
                const total_score = 1;
                let cur_score = updatedEssentialCoursesDict.ScienceMath.length;

                cur_score = Math.min(cur_score, total_score);
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("additional_required_courses");
                                    delayedScrollDown();
                                    FlashENE('science/math');
                                }}
                            >
                                Go to Electives and Essentials: Science/Math
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
            },
            () => {
                const success_text = "Technical elective requirement is met.";
                const fail_text = "At least three technical elective courses must be taken.";
    
                let success = updatedEssentialCoursesDict.TechnicalElective.length >= 3;
                let details = [];
    
                const total_score = 2;
                let cur_score = Math.min(updatedEssentialCoursesDict.TechnicalElective.length / 3 * 2, 2);
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("additional_required_courses");
                                    delayedScrollDown();
                                    FlashENE('technical_electives');
                                }}
                            >
                                Go to Electives and Essentials: Technical Elective
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
    
            },
            () => {
                const success_text = "Free elective requirement is met.";
                const fail_text = "At least one Free elective course must be taken.";
    
                let success = updatedEssentialCoursesDict.FreeElective.length >= 1;
                let details = [];
    
                const total_score = 1;
                let cur_score = Math.min(updatedEssentialCoursesDict.FreeElective.length, 1);
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("additional_required_courses");
                                    delayedScrollDown();
                                    FlashENE('free_elective');
                                }}
                            >
                                Go to Electives and Essentials: Free Elective
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
            },
            () => {
                const success_text = "Engineering Economics(ECE471) is passed.";
                const fail_text = "Engineering Economics(ECE471) is not passed.";
    
                let success = updatedEssentialCoursesDict.EngineeringEconomic.length === 1;
                let details = [];
                const total_score = 1;
                let cur_score = updatedEssentialCoursesDict.EngineeringEconomic.length;
    
                cur_score = Math.min(cur_score, total_score);
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("additional_required_courses");
                                    delayedScrollDown();
                                    FlashENE('engineering_economics');
                                }}
                            >
                                Go to Electives and Essentials: Engineering Economics
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
            },
            () => {
                const success_text = "HSS/CS requirement is met.";
                const fail_text = "Not enough HSS/CS course is taken.";
    
                let success = updatedEssentialCoursesDict.HSS.length >= 2 && updatedEssentialCoursesDict.CS.length >= 2;
                let details = [];
                const total_score = 4;
                let cur_score = updatedEssentialCoursesDict.HSS.length + updatedEssentialCoursesDict.CS.length;
                cur_score = Math.min(cur_score, total_score);

                console.log("Cur score:", updatedEssentialCoursesDict, cur_score, total_score);
    
                if (!success) {
                    details.push({
                        key: alphanumerical(),
                        label: (
                            <span
                                onClick={async () => {
                                    setLowerTabPanelActiveKey("additional_required_courses");
                                    delayedScrollDown();
                                    FlashENE('HSS&CS');
                                }}
                            >
                                Go to Electives and Essentials: HSS/CS
                            </span>
                        ),
                        icon: <RightOutlined></RightOutlined>,
                    });
                }
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, details, total_score, cur_score]
            },
            () => {
                const success_text = "All CEAB requirements are met.";
                const fail_text = "There are some CEAB requirements that were not met.";
    
                const total_score = updatedCeabData.length;
                let cur_score = 0;
    
                let dropdown_list = [];
                for (let requirement_id = 0; requirement_id < updatedCeabData.length; requirement_id++) {
                    let requirement = updatedCeabData[requirement_id];
    
                    // console.log(requirement, requirement.outstanding);
                    if (requirement.outstanding > 0) {
                        dropdown_list.push({
                            key: `${requirement_id + 1}`,
                            label: (
                                <div
                                    onClick={() => {
                                        setLowerTabPanelActiveKey("ceab_requirements_tab");
                                        delayedScrollDown();
                                        FlashCEABRow(requirement.categories);
                                    }}
                                >
                                    <span style={{ fontWeight: "bold", color: "red" }}>Unsatisfied Requirement:</span>{" "}
                                    {requirement.categories}
                                </div>
                            ),
                            icon: <RightOutlined></RightOutlined>,
                        });
                    } else {
                        cur_score += 1;
                    }
                }
    
                cur_score = Math.min(cur_score, total_score);
    
                // console.log(dropdown_list)
                const success = dropdown_list.length === 0;
    
                const return_text = success ? success_text : fail_text;
    
                return [success, return_text, dropdown_list, total_score, cur_score];
            },
        ];
    
        const return_list = [];
        const details_list = [];
    
        console.log("====================== Graduation check list start ======================");
    
        console.log('updatedEssentialCoursesDict:', updatedEssentialCoursesDict);

        for (const check of all_checks) {
            const [cur_check_passed, text, details, max_score, score] = check();
    
            if (!cur_check_passed) {
                details_list.push(text);
            }
            return_list.push({
                status: cur_check_passed,
                text: text,
                details: details,
                max_score,
                score
            });
        }
    
        let profile_status = 0;
        let total_score = return_list.reduce((acc, item) => {
            return acc + item.max_score
        }, 0);
    
        for (const cond of return_list) {
            profile_status += cond.score;
        }
        profile_status /= total_score;

        console.log("Calculated profile status:", Math.round(profile_status * 100), graduationProgram);
    
        let newActiveProfile = {
            ...activeProfile,
            details: details_list,
            status: Math.round(profile_status * 100),
            courses: unformatCourses(scheduleData.current),
            ece: graduationProgram
        }
    
        // If new status is different, update
        if (activeProfile.status !== Math.round(profile_status * 100)) {
            console.log(`activeProfile.status: ${activeProfile.status}`, `Math.round(profile_status * 100): ${Math.round(profile_status * 100)}`)
    
            // TODO: Get a new endpoint for this
            // save_item_to_server('update_profile', { profile: newActiveProfile });
            // console.log("Update profile:", {
            //     ...activeProfile,
            //     details: details_list,
            //     status: Math.round(profile_status * 100),
            //     ece: graduationProgram
            // });

            setActiveProfile({
                ...activeProfile,
                details: details_list,
                status: Math.round(profile_status * 100),
                ece: graduationProgram
            });
        }
    
        console.log("====================== Graduation check list end ======================");
    
        return return_list;
    };

    function calculateEssentialCoursesDict(updatedPossibleGraduationScenarios){
        console.log("============== calculateEssentialCoursesDict start ==============");
        let usedEssentialCoursesDict = {
            EngineeringEconomic: [],
            Capstone: [],
            ScienceMath: [],
            TechnicalElective: [],
            HSS: [],
            CS: [],
            FreeElective: [],
        };

        let activeGraduationScenario = getActiveGraduationScenario(updatedPossibleGraduationScenarios);

        const EE_course_found = findCourse("ECE472");
        if (EE_course_found !== null && EE_course_found.status !== 2) {
            usedEssentialCoursesDict.EngineeringEconomic.push("ECE472");
        }

        const available_capstone_courses = ["ECE496", "APS490", "BME498"];

        // Find which capstone the student took
        for (const cur_course_code of available_capstone_courses) {
            const foundCourse = findCourse(cur_course_code);

            if (foundCourse !== null && foundCourse.status !== 2) {
                usedEssentialCoursesDict.Capstone.push(cur_course_code);
                break;
            }
        }

        let usedKDCourses = [];
        for (const key in activeGraduationScenario) {
            usedKDCourses.push(...activeGraduationScenario[key].kernel);
            usedKDCourses.push(...activeGraduationScenario[key].depth);
        }

        // usedKDCourses = usedKDCourses.splice(0, 8);

        console.log("sortedKDMatrix", activeGraduationScenario, usedKDCourses);

        for (const term of scheduleData.current) {
            for (const course of term.term_courses) {
                // Skip capstone
                if (available_capstone_courses.includes(course.code.split(" ")[0])) {
                    continue;
                }

                if (usedKDCourses.includes(course.code)) {
                    console.log(`${course.code} used`);
                    continue;
                }

                if (course.status === 2) {
                    continue;
                }

                if (course.area && course.area.includes("7") && usedEssentialCoursesDict.ScienceMath.length < 1) {
                    if (!usedEssentialCoursesDict.ScienceMath.includes(course.code)) {
                        usedEssentialCoursesDict.ScienceMath.push(course.code);
                    }
                } else if ((course.type === "D" || course.type === "K" || course.type === "O") && usedEssentialCoursesDict.TechnicalElective.length < 3) {
                    if (!usedEssentialCoursesDict.TechnicalElective.includes(course.code)) {
                        usedEssentialCoursesDict.TechnicalElective.push(course.code);
                    }
                } else if (course.type === "H" && usedEssentialCoursesDict.HSS.length < 2) {
                    if (!usedEssentialCoursesDict.HSS.includes(course.code)) {
                        usedEssentialCoursesDict.HSS.push(course.code);
                    }
                } else if (course.type === "C" && usedEssentialCoursesDict.CS.length < 2) {
                    if (!usedEssentialCoursesDict.CS.includes(course.code)) {
                        usedEssentialCoursesDict.CS.push(course.code);
                    }
                } else if ((course.type === "D" || course.type === "K" || course.type === "O")) {
                    console.log("Free", course.code)
                    if (!usedEssentialCoursesDict.FreeElective.includes(course.code)) {
                        usedEssentialCoursesDict.FreeElective.push(course.code);
                    }
                }

            }
        }

        console.log("usedEssentialCoursesDict", usedEssentialCoursesDict);
        setEssentialCoursesDict(usedEssentialCoursesDict);

        console.log("============== calculateEssentialCoursesDict ends ==============");
        return usedEssentialCoursesDict;
    }

    useEffect(() => {
        // console.log("Trigger", !scheduleData.current.length, !activeProfile, (activeProfile.ece !== graduationProgram));
        if(!graduationProgram && activeProfile.ece !== graduationProgram){
            setGraduationProgram(activeProfile.ece);
            return;
        }

        if(!scheduleData.current.length || !activeProfile){
            return;
        }

        console.log("Graduation eligibility recalculation triggered:", activeProfile, scheduleData.current, graduationProgram);

        // Recalculate ceab data when course list changes
        let new_ceab_value = calculateCEABData(scheduleData);

        setCEABDetailsData(new_ceab_value);

        let [sortedKDList, scenarios] = calculateGraduationScenario();

        setAllCoursesKDList(sortedKDList);
        setPossibleGraduationScenarios(scenarios);

        let usedEssentialCoursesDict = calculateEssentialCoursesDict(scenarios);

        const checklist = getGraduationCheckList(usedEssentialCoursesDict, scenarios, new_ceab_value);

        console.log('graduation checklist update:', checklist);
        setGraduationCheckList(checklist);

        console.log("Graduation eligibility recalculation done.", sortedKDList, scenarios, usedEssentialCoursesDict, checklist);
    }, [formattedCourseData, graduationProgram])

    console.log("Graduation Checker Rerender")
    return (
        <div style={{ width: "100%", minHeight: "350px", backgroundColor: "white" }}>
            <div
                style={{
                    left: 0,
                    height: '2px',
                    width: `${activeProfile.status}%`,
                    backgroundColor: `hsl(${activeProfile.status}, 100%, 45%)`,
                    position: 'relative',
                    top: '-2px'
                }}
            />
            <Tabs tabPosition="left" items={lowerTabsContent} activeKey={lowerTabPanelActiveKey} onChange={setLowerTabPanelActiveKey} />
        </div >
    )
}

export default GraduationChecker;
