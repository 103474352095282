import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Input, Button, Menu, Tooltip, Dropdown, Layout, theme, Space, message, Popover } from "antd";
import {
    AudioOutlined,
    CopyOutlined,
    EditOutlined,
    PauseOutlined,
    ReloadOutlined,
    CheckOutlined,
    CloseOutlined,
    DownCircleOutlined,
    RedoOutlined,
    SyncOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
    LeftOutlined,
    PlusCircleOutlined,
    EllipsisOutlined,
    CheckCircleOutlined,
    ToolOutlined,
} from "@ant-design/icons";
import "../Stylesheets/App.css";
import { alphanumerical, sleep, server_api_addr, useRequestWithNavigate, dc, compressData, calculateCEABData } from "../utils";
import { useAppContext } from "../App/AppContext";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import useAssistantActions from "../AssistantActions";
import rehypeRaw from "rehype-raw";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMetrics } from "../SiteMetricContext";
import rehypeSanitize, { defaultSchema } from "rehype-sanitize";

const customSchema = {
    ...defaultSchema,
    tagNames: [...(defaultSchema.tagNames || []), "thinking"],
};


const { TextArea } = Input;
const pfp_width = "70px";
const pfp_height = "70px";
const { Content, Sider } = Layout;

const CopyToClipboardButton = React.memo(({ content }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = useCallback(() => {
        navigator.clipboard
            .writeText(content)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    }, [content]);

    return (
        <Tooltip placement="bottom" arrow title={"Copy"}>
            <Button
                onClick={handleCopy}
                type="text"
                size="small"
                style={{ marginLeft: "5px" }}
                shape="circle"
                icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
            />
        </Tooltip>
    );
});

const defaultStyle = {
    cursor: "pointer",
    fontSize: "30px",
    marginRight: "10px",
    transition: "transform 0.175s ease",
};

const ChatSettingDropDownButton = React.memo(({ menuItems }) => {
    const [hovered, setHovered] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const handleHover = useCallback(
        async (e) => {
            setHovered(e);
            if (e) {
                await sleep(150);
            }
            setDropDownOpen(e);
        },
        [setHovered, setDropDownOpen]
    );

    return (
        <Dropdown
            menu={{ items: menuItems }}
            onOpenChange={handleHover}
            open={dropDownOpen}
            trigger="click"
            placement="top"
        >
            <DownCircleOutlined
                style={{
                    ...defaultStyle,
                    transform: hovered ? "rotate(-180deg)" : "rotate(-90deg)",
                }}
            />
        </Dropdown>
    );
});

const ChatNameRow = React.memo(({ item, switchCurConv, newChatSession }) => {
    const { activeProfile, setActiveProfile, save_item_to_server } = useAppContext();
    const [editName, setEditName] = useState(false);
    const [inputValue, setInputValue] = useState(item.name);
    const inputRef = useRef(null);
    const origName = useRef(item.name);

    const updateRowName = useCallback(() => {
        const tempProfile = { ...activeProfile };

        for (const conversation of tempProfile.chats) {
            if (conversation.key === item.key) {
                conversation.name = inputValue;
            }
        }

        setActiveProfile(tempProfile);

        // TODO: Get a new endpoint specifically responsible for this
        // save_item_to_server('update_profile', {profile: tempProfile}, true, "", 'Chat name successfully updated!', "");
    }, [activeProfile, item.key, inputValue, setActiveProfile]);

    useEffect(() => {
        if (inputRef.current) {
            if (editName) {
                inputRef.current.focus();
            } else {
                inputRef.current.blur();
            }
        }
    }, [editName]);

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            {!editName ? (
                <span
                    onClick={() => {
                        switchCurConv(item);
                    }}
                    style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "clip",
                        verticalAlign: "top",
                        width: "90%",
                    }}
                >
                    {item.name}
                </span>
            ) : (
                <Input
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.code === "Enter") {
                            setEditName(false);
                            origName.current = inputValue;
                            updateRowName();
                        } else if (e.code === "Escape") {
                            setEditName(false);
                            setInputValue(origName.current);
                        }
                    }}
                    onBlur={() => {
                        setEditName(false);
                        updateRowName();
                    }}
                />
            )}

            <Dropdown
                trigger={"click"}
                menu={{
                    items: [
                        {
                            key: "renameConv",
                            label: "Rename",
                            onClick: () => {
                                setEditName(true);
                            },
                        },
                        {
                            key: "deleteConv",
                            label: (
                                <span
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Delete
                                </span>
                            ),
                            onClick: () => {
                                const tempProfile = { ...activeProfile };

                                if (item.key === newChatSession.current?.key) {
                                    newChatSession.current = null;
                                }
                                const newChats = tempProfile.chats.filter(
                                    (conversation) => conversation.key !== item.key
                                );
                                tempProfile.chats = newChats;

                                if (newChats.length === 0) {
                                    message.error("Can't delete the last chat.")
                                } else {
                                    // TODO: Get a new endpoint specifically responsible for this
                                    // save_item_to_server('update_profile', {profile: tempProfile}, true, "", 'Chat successfully deleted!', "");
                                    save_item_to_server('update_profile_chats', {
                                        key: tempProfile.key,
                                        chats: tempProfile.chats
                                    }, true, "", 'Chat successfully deleted!', "");
                                    setActiveProfile(tempProfile);
                                }
                            },
                        },
                    ],
                }}
            >
                <EllipsisOutlined className="ChatNameEllipse" />
            </Dropdown>
        </div>
    );
});

const SpeakModelResponseButton = React.memo(({ content }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const utteranceRef = useRef(null);

    const handleModelResponseReadButton = useCallback(() => {
        if (isSpeaking) {
            speechSynthesis.cancel();
            setIsSpeaking(false);
        } else {
            utteranceRef.current = new SpeechSynthesisUtterance(content);
            utteranceRef.current.onend = () => setIsSpeaking(false);
            speechSynthesis.speak(utteranceRef.current);
            setIsSpeaking(true);
        }
    }, [content, isSpeaking]);

    return (
        <Tooltip placement="bottom" arrow title={"Read"}>
            <Button onClick={handleModelResponseReadButton} type="text" size="small" shape="circle">
                <span
                    style={{
                        position: "absolute",
                        transition: "opacity 0.2s ease-out, transform 0.5s ease-out",
                        opacity: isSpeaking ? 1 : 0,
                        transform: isSpeaking ? "scale(1)" : "scale(0.8)",
                    }}
                >
                    <PauseOutlined />
                </span>
                <span
                    style={{
                        position: "absolute",
                        transition: "opacity 0.2s ease-out, transform 0.5s ease-out",
                        opacity: isSpeaking ? 0 : 1,
                        transform: isSpeaking ? "scale(0.8)" : "scale(1)",
                    }}
                >
                    <AudioOutlined />
                </span>
            </Button>
        </Tooltip>
    );
});

const ChatRowUser = React.memo(({ index, turn, chatHistory, setChatHistory }) => {
    const [displayEditButton, setDisplayEditButton] = useState(true);
    const [displayEditButtonHover, setDisplayEditButtonHover] = useState(false);

    const [userInputDisabled, setUserInputDisabled] = useState(true);

    const [currentDisplayText, setCurrentDisplayText] = useState(turn["content"]);
    const [originalInputText, setOriginalInputText] = useState(turn["content"]);

    const onUserRowHoverEnter = useCallback(() => {
        setDisplayEditButtonHover(true);
    }, []);

    const onUserRowHoverEnd = useCallback(() => {
        setDisplayEditButtonHover(false);
    }, []);

    const updateCurrentText = useCallback((e) => {
        setCurrentDisplayText(e.target.value);
    }, []);

    useEffect(() => {
        setCurrentDisplayText(turn["content"]);
    }, [turn]);

    const updateUserText = useCallback(() => {
        setDisplayEditButton(true);
        setUserInputDisabled(true);
        setOriginalInputText(currentDisplayText);

        const newChatHistory = [...chatHistory];

        newChatHistory[index]["content"] = currentDisplayText;

        setChatHistory(newChatHistory);

        // regenerateResponse()
    }, [chatHistory, currentDisplayText, index, setChatHistory]);

    const handleCancelEdit = useCallback(() => {
        setDisplayEditButton(true);
        setUserInputDisabled(true);
        setCurrentDisplayText(originalInputText);
    }, [originalInputText]);

    return (
        <div
            key={index}
            className="ChatRow"
            onMouseOver={onUserRowHoverEnter}
            onMouseLeave={onUserRowHoverEnd}
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                marginBottom: "10px",
                marginLeft: "25px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={() => {
                        setUserInputDisabled(false);
                        setDisplayEditButton(false);
                    }}
                    style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "15px",
                        visibility: displayEditButtonHover && displayEditButton ? "visible" : "hidden",
                    }}
                    shape="circle"
                >
                    <EditOutlined />
                </Button>

                {userInputDisabled ? (
                    <div className="UserChatContent">
                        <p
                            style={{
                                whiteSpace: "pre-wrap",
                                display: userInputDisabled ? "" : "none",
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                        >
                            {currentDisplayText}
                        </p>
                    </div>
                ) : (
                    <div
                        className="UserChatContent"
                        style={{
                            width: "100%",
                        }}
                    >
                        <TextArea
                            variant="borderless"
                            value={currentDisplayText}
                            style={{
                                width: "100%",
                                fontSize: "11pt",
                                color: "black",
                            }}
                            disabled={userInputDisabled}
                            onChange={updateCurrentText}
                            autoSize
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                onClick={handleCancelEdit}
                                style={{
                                    display: userInputDisabled ? "none" : "",
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={updateUserText}
                                style={{
                                    display: userInputDisabled ? "none" : "",
                                    marginLeft: "15px",
                                }}
                                type="primary"
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            <div
                style={{
                    width: pfp_width,
                    height: pfp_height,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/spongebob.svg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    transform: "scaleX(-1)",
                }}
            />
        </div>
    );
});

const ChatRowToolResult = React.memo(({ index, tools }) => {
    const pillStyle = {
        display: "inline-flex",
        alignItems: "center",
        borderRadius: "16px",
        fontSize: "12px",
        padding: "2px 8px",
        marginRight: "6px",
        marginTop: "6px",
        whiteSpace: "nowrap",
    };

    const toolUseStyle = {
        ...pillStyle,
        backgroundColor: "#fafafa",
        border: "1px solid #e6e6e6",
        color: "#666",
        cursor: 'pointer'
    };

    const toolResultStyle = {
        ...pillStyle,
        backgroundColor: "#e6f7ff",
        border: "1px solid #91d5ff",
        color: "#1890ff",
        cursor: 'pointer'
    };

    if (!tools || tools.length === 0) {
        return null;
    }

    // console.log("Got tool:", tools);
    return (
        <div style={{ margin: "4px 0 8px 0" }}>

            {tools.map((tool, tool_index) => {
                if (tool.type === "tool_use") {
                    if (tool.name === 'thinking' && tools.length !== 1) {
                        return null;
                    }
                    return (
                        <Popover
                            content={tool['reason']}
                            title={`Reasoning`}
                            trigger="click"
                            overlayStyle={{
                                width: "250px",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                            }}
                            key={`ToolUse-${index}-${tool_index}`}
                        >
                            <div style={toolUseStyle}>
                                <ToolOutlined style={{ marginRight: "4px" }} />
                                {tool.name}
                            </div>
                        </Popover>
                    );
                } else if (tool.type === "tool_result") {
                    let tool_fail = tool.content.toLowerCase().includes('not successful');

                    return (
                        <Popover
                            content={tool.content}
                            title={`Tool Result`}
                            key={`ToolResult-${index}-${tool_index}`}
                            trigger="click"
                            overlayStyle={{
                                width: "250px",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                // scroll enable
                                overflow: "auto",
                                maxHeight: "200px",
                            }}
                        >
                            <div style={{
                                ...toolResultStyle,
                                backgroundColor: tool_fail ? "rgb(255 213 213)" : "#e6f7ff",
                                border: tool_fail ? "1px solid #ff4d4f" : "1px solid #91d5ff",
                                color: tool_fail ? 'red' : '#1890ff',
                            }}>
                                {
                                    tool_fail ? (
                                        <CloseOutlined style={{ marginRight: "4px" }} />
                                    ) : (
                                        <CheckCircleOutlined style={{ marginRight: "4px" }} />
                                    )
                                }
                                {tool_fail ? "Failed" : "Result"}
                            </div>
                        </Popover>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
});

const ChatRowAI = React.memo(({ index, turn: assistant_turn_contents, cur_gen = false, generation_over = false, regenerateResponse }) => {
    function getChatRenderContent(assistant_turn_contents) {
        /**
         * A helper function that finds <thinking>...</thinking> blocks
         * in the given `text` and returns:
         * [ cleanedText, reasoningBlock ]
         *
         * For example: "Hi<thinking>secret</thinking>bye" 
         * becomes: ["Hibye", "secret"]
         */
        function splitThinking(text = "") {
            // This regex finds the first <thinking>...</thinking> block (non-greedy).
            // If you want to handle multiple <thinking> blocks in a single text, you'll need a loop or global regex.
            const regex = /<thinking>(?:(?:.|\n)*?)<\/thinking>/;

            console.log("Got text:", text);
            const match = text.match(regex);

            if (match) {
                // `match[0]` is the entire "<thinking>...</thinking>" text
                // Remove it from the original text for the user-facing portion.
                const cleanedText = text;
                // const cleanedText = text.replace(regex, "").trim();

                // Extract reasoning from inside the <thinking>...</thinking> tags
                const reasoning = match[0]
                    .replace("<thinking>", "")
                    .replace("</thinking>", "")
                    .trim();

                return [cleanedText, reasoning];
            }

            // If no match, assume the entire text is acknowledgment
            return [text.trim(), ""];
        }

        function parseConversationIntoSubTurns(conversation) {
            const subTurns = [];

            // We'll keep track of the current sub-turn we're building
            let currentSubTurn = {
                initial: "", // The text outside <thinking>
                tools: [],   // The tools used, plus any tool_results or "thinking" placeholders
                answer: "",  // The final "answer" message
            };

            // We'll store any "thinking" content temporarily if we want to attach it
            // to a "thinking" tool or to the next real tool. In this case, we push it
            // immediately as a placeholder "thinking" tool.
            let currentReasoning = "";

            /**
             * Helper to finalize the current sub-turn and begin a new one.
             * This pushes the currentSubTurn into `subTurns` only if it has something,
             * then resets the structure.
             */
            const finalizeSubTurnAndStartNext = () => {
                const hasContent =
                    currentSubTurn.initial ||
                    currentSubTurn.tools.length ||
                    currentSubTurn.answer;
                if (hasContent) {
                    subTurns.push({ ...currentSubTurn });
                }
                currentSubTurn = { initial: "", tools: [], answer: "" };
                currentReasoning = "";
            };

            for (let i = 0; i < conversation.length; i++) {
                const message = conversation[i];
                const { role, content } = message;

                // ===========================================================
                // 1. If it's an Assistant message
                // ===========================================================
                if (role === "assistant") {
                    // content could be a string OR an array
                    if (Array.isArray(content)) {
                        // If currentSubTurn is not empty, it means we’re starting a brand new sub-turn
                        // for this new assistant message.
                        if (
                            currentSubTurn.initial ||
                            currentSubTurn.tools.length ||
                            currentSubTurn.answer
                        ) {
                            finalizeSubTurnAndStartNext();
                        }

                        // Process each item in the assistant's content array
                        content.forEach((item) => {
                            const { type } = item;

                            if (type === "text") {
                                // Extract visible text + reasoning
                                const [ack, reasoning] = splitThinking(item.text);

                                // If we haven't set an initial message yet, do so
                                if (!currentSubTurn.initial) {
                                    currentSubTurn.initial = ack;
                                } else {
                                    currentSubTurn.initial += ` ${ack}`;
                                }

                                // If there's <thinking> content, store it as a "thinking" placeholder tool
                                if (reasoning) {
                                    currentSubTurn.tools.push({
                                        type: "tool_use",
                                        id: "thinking_placeholder_" + Math.random().toString(16).slice(2),
                                        name: "thinking",
                                        input: { text: reasoning },
                                        reason: "Placeholder for <thinking> block"
                                    });
                                }

                                // Also hold onto the reasoning in case you want to attach it
                                // to a subsequent real tool. But for demonstration, we skip that:
                                currentReasoning = reasoning;
                            } else if (type === "tool_use") {
                                // This is a real tool usage by the assistant
                                currentSubTurn.tools.push({
                                    ...item,
                                    // Optionally attach the previously found reasoning 
                                    // if you want to combine them. Otherwise omit.
                                    reason: currentReasoning,
                                });
                                currentReasoning = "";
                            }
                        });
                    } else {
                        // If content is a single string (e.g. final answer or short text)
                        if (
                            currentSubTurn.initial ||
                            currentSubTurn.tools.length ||
                            currentSubTurn.answer
                        ) {
                            finalizeSubTurnAndStartNext();
                        }

                        // Attempt to parse out <thinking> from the single string
                        const [ack, reasoning] = splitThinking(content);

                        currentSubTurn.initial = ack;

                        if (reasoning) {
                            currentSubTurn.tools.push({
                                type: "tool_use",
                                id: "thinking_placeholder_" + Math.random().toString(16).slice(2),
                                name: "thinking",
                                input: { text: reasoning },
                                reason: reasoning
                            });
                        }

                        currentReasoning = reasoning;
                    }
                }

                // ===========================================================
                // 2. If it's a User message
                // ===========================================================
                else if (role === "user") {
                    if (Array.isArray(content)) {
                        content.forEach((item) => {
                            if (item.type === "tool_result") {
                                currentSubTurn.tools.push(item);
                            }
                        });
                    }
                }
            }

            // If there’s a trailing sub-turn that has data, push it
            if (
                currentSubTurn.initial ||
                currentSubTurn.tools.length ||
                currentSubTurn.answer
            ) {
                subTurns.push(currentSubTurn);
            }

            return subTurns;
        }

        return parseConversationIntoSubTurns(assistant_turn_contents);
    }

    let render_content = getChatRenderContent(assistant_turn_contents);

    console.log("Got render content:", render_content);

    const MarkdownRender = ({ value, cur_renderer_lastest, generation_over }) => {
        const customSchema = {
            ...defaultSchema,
            attributes: {
                ...defaultSchema.attributes,
                div: [
                    // Keep any attributes already allowed on `div`:
                    ...(defaultSchema.attributes?.div || []),
                    // Allow style
                    "style",
                ],
                span: [
                    ...(defaultSchema.attributes?.span || []),
                    "style"
                ],
            },
        };
        let display_value =
            !generation_over && cur_renderer_lastest
                ? `${value} <span style="display:inline-block; transform: scale(4); transform-origin:center; margin-left:4px; line-height:1; translate: 0px -2px;">•</span>`
                : value;

        // console.log("display_value", display_value, generation_over, cur_renderer_lastest);

        // Allow partial tag
        if (display_value.match(/<thinking>(?:(?:.|\n)*?)/)) {
            console.log("Thinking found");
            display_value = display_value
                .replace(
                    /<thinking>/g,
                    `<div style="background: #f0f8ff; border-left: 4px solid #007bff; padding: 8px 12px; margin: 8px 0; font-style: italic; color: #333; animation: pulse 2s infinite;">`
                )
            
            if(display_value.match(/<\/thinking>/)){
                display_value = display_value.replace("</thinking>", "</div>");
            } else {
                display_value += "\n</div>"
            }

            display_value = display_value.trim();
        }

        return (
            <Markdown
                remarkPlugins={[remarkGfm]}
                // If you're sanitizing, include your customSchema
                rehypePlugins={[rehypeRaw, [rehypeSanitize, customSchema]]}
                // If you want *all* raw HTML without sanitizing, just use [rehypeRaw].
                // rehypePlugins={[rehypeRaw]}

                components={{
                    code({ children, className, node, ...rest }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return match ? (
                            <SyntaxHighlighter
                                {...rest}
                                PreTag="div"
                                language={match[1]}
                                children={String(children).replace(/\n$/, "")}
                            />
                        ) : (
                            <code {...rest} className={className}>
                                {children}
                            </code>
                        );
                    }
                }}
            >
                {display_value}
            </Markdown>
        );
    };

    const AIRowTray = () => {
        const handleModelResponseRegenerateButton = useCallback(() => {
            console.log("Regenerate", index);
            regenerateResponse(index);
        }, []);

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: "15px",
                }}
            >
                <SpeakModelResponseButton content={assistant_turn_contents["content"]} />
                <CopyToClipboardButton content={assistant_turn_contents["content"]} />
                {
                    index !== 0 && (
                        <Tooltip placement="bottom" title={"Retry"}>
                            <Button
                                onClick={handleModelResponseRegenerateButton}
                                type="text"
                                size="small"
                                style={{ marginLeft: "5px" }}
                                shape="circle"
                                icon={<ReloadOutlined />}
                            />
                        </Tooltip>
                    )
                }
            </div>
        )
    }

    // console.log("Got turn:", assistant_turn_contents, index, tool_use_contents);

    return (
        <div className="AIChatRow">
            <div
                style={{
                    width: pfp_width,
                    height: pfp_height,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/smartgary.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                }}
            />
            <div
                style={{
                    width: "calc(90% - 100px)",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ marginLeft: "15px", width: "100%" }}>
                    {
                        render_content.map((sub_turn, sub_turn_index) => {
                            return (
                                <div key={`turn-${index}-subturn-${sub_turn_index}-container`}>
                                    <MarkdownRender generation_over={generation_over} key={`turn-${index}-initial-subturn-${sub_turn_index}`} value={sub_turn['initial']} cur_renderer_lastest={cur_gen && sub_turn['tools'].length === 0} />
                                    <ChatRowToolResult key={`turn-${index}-toolresult-subturn-${sub_turn_index}`} tools={sub_turn['tools']} />
                                    {
                                        sub_turn['answer'] !== "" ? (
                                            <MarkdownRender generation_over={generation_over} key={`turn-${index}-answer-subturn-${sub_turn_index}`} value={sub_turn['answer']} cur_renderer_lastest={cur_gen && sub_turn['initial'] !== "" && sub_turn['tools'].length !== 0} />
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        (render_content['answer'] !== "" || render_content['initial'] !== "") && <AIRowTray />
                    }
                </div>
            </div>
        </div>
    );
});

function groupConversationsByDate(conversations) {
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    const grouped = {
        today: [],
        yesterday: [],
        "last 7 days": [],
        "last 30 days": [],
        earlier: [],
    };

    conversations.forEach((conversation) => {
        const lastChatDate = new Date(conversation["timestamp"]);
        const diffInDays = Math.floor((today - lastChatDate) / oneDay);

        if (diffInDays === 0) {
            grouped.today.push(conversation);
        } else if (diffInDays === 1) {
            grouped.yesterday.push(conversation);
        } else if (diffInDays <= 7) {
            grouped["last 7 days"].push(conversation);
        } else if (diffInDays <= 30) {
            grouped["last 30 days"].push(conversation);
        } else {
            grouped.earlier.push(conversation);
        }
    });

    // Sort conversations within each group by timestamp
    for (const key in grouped) {
        grouped[key].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }

    return Object.entries(grouped).map(([label, sessions]) => ({ label, sessions }));
}

const ChatRows = ({ curChatHist, curConversation, setCurConversation, generation_over, regenerateResponse }) => {
    function hasToolUse(msg) {
        // Helper: checks if this assistant message has a tool_use in the content array
        return (
            msg?.role === "assistant" &&
            Array.isArray(msg.content) &&
            msg.content.some((item) => item.type === "tool_use")
        );
    }

    function hasToolResult(msg) {
        // Helper: checks if this user message has a tool_result in the content array
        return (
            msg?.role === "user" &&
            Array.isArray(msg.content) &&
            msg.content.some((item) => item.type === "tool_result")
        );
    }

    function groupChatTurns(messages) {
        const groupedTurns = [];
        let i = 0;

        while (i < messages.length) {
            const currentMsg = messages[i];

            // If this message is an assistant message with a tool_use,
            // we keep grouping assistant(tool_use) → user(tool_result) pairs
            if (hasToolUse(currentMsg)) {
                const turn = [];

                // Keep walking as long as we see assistant(tool_use) → user(tool_result)
                while (i < messages.length && hasToolUse(messages[i])) {
                    // 1) Push the assistant (tool_use) message
                    turn.push(messages[i]);
                    i++;

                    // 2) If next is user(tool_result), push it, otherwise break
                    if (i < messages.length && hasToolResult(messages[i])) {
                        turn.push(messages[i]);
                        i++;
                    } else {
                        break;
                    }
                }

                // After collecting all tool_use → tool_result pairs,
                // check if the next message is an assistant "final" (i.e., normal text) 
                // that should be appended right away
                if (
                    i < messages.length &&
                    messages[i]?.role === "assistant" &&
                    !hasToolUse(messages[i]) // it's assistant but not a tool_use
                ) {
                    turn.push(messages[i]);
                    i++;
                }

                groupedTurns.push(turn);
            } else {
                // Otherwise, just push this single message in its own turn
                groupedTurns.push([currentMsg]);
                i++;
            }
        }

        return groupedTurns;
    }


    let groupedConversation = groupChatTurns(curChatHist);

    console.log("groupedConversation:", groupedConversation);

    return (
        groupedConversation.map((item, index) => {
            const payload = {
                index: index,
                turn: item,
                chatHistory: curConversation,
                setChatHistory: setCurConversation,
            };

            if (item[0].role === "assistant") {
                return (
                    <ChatRowAI
                        {...payload}
                        key={`chatRow-${index}`}
                        cur_gen={index === groupedConversation.length - 1}
                        generation_over={generation_over}
                        regenerateResponse={regenerateResponse}
                    />
                );
            } else {
                payload['turn'] = item[0];
                return (
                    <ChatRowUser
                        {...payload}
                        key={`chatRow-${index}`}
                    />
                )
            }

        })
    )
}


const ChatInput = ({ currentChatValue, buttonDisabled, chatSettingDropdownItems, sendChat, courseDropQC }) => {
    const [inputValue, setInputValue] = useState(currentChatValue.current);

    function clear() {
        setInputValue("");
        currentChatValue.current = "";
    }

    const handleInputChange = useCallback((event) => {
        setInputValue(event.target.value);
        currentChatValue.current = event.target.value;
    }, []);

    return (
        <Space.Compact style={{ width: "100%" }}>
            <ChatSettingDropDownButton menuItems={chatSettingDropdownItems} />

            <Input
                placeholder="Ask me anything..."
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={() => {
                    sendChat();
                    clear();
                }}
                style={{ height: 40, borderRadius: "6px 0px 0px 6px" }}
                key={"userInput"}
                onDragOver={(e) => e.preventDefault()}
                onDrop={courseDropQC}
            />
            <Button
                type="primary" onClick={() => {
                    sendChat();
                    clear();
                }}
                loading={buttonDisabled}
                style={{ height: 40 }}
            >
                Send
            </Button>
        </Space.Compact>

    )
}

const ChatInterface = () => {
    const {
        draggingCard: draggingCourse,
        queryCourses,
        showServerSaveMessage,
        setQueryCourses,
        formattedCourseData,
        activeProfile,
        setActiveProfile,
        setFormattedCourseData,
        currentUserInfo,
        save_item_to_server,
        scheduleData
    } = useAppContext();

    const rootRef = useRef(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const currentChatValue = useRef("");

    const [groupedChatHistory, setGroupedChatHistory] = useState([]);
    const [menuSelectedKey, setMenuSelectedKey] = useState("");

    const [availableActions, performAction] = useAssistantActions();

    const [curConversation, setCurConversation] = useState([]);

    const newChatSession = useRef(null);

    const request = useRequestWithNavigate();

    const { updateMetrics } = useMetrics(); // Access shared metrics via useRef

    const [chatHistoryCollapsed, setChatHistoryCollapsed] = useState(true);
    const [collapseButtonVisible, setCollapseButtonVisible] = useState(true);
    const historyBarWidth = 200;
    const refreshInterval = 100;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    // Auto-scrolling variables and functions
    const messagesStartRef = useRef(null);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const isAutoScrolling = useRef(false);

    const [isAtTop, setIsAtTop] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [userScrolled, setUserScrolled] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const [responseError, setResponseError] = useState(null);
    const [responseOver, setResponseOver] = useState(true);

    const navigate = useNavigate();

    const handleScroll = useCallback((e) => {
        if (responseOver) {
            return;
        }
        const target = e.target;
        const bottom =
            Math.round(target.scrollHeight - target.scrollTop) ===
            Math.round(target.clientHeight);
        setIsAtBottom(bottom);

        const top = target.scrollTop === 0;
        setIsAtTop(top);

        if (!bottom && !isAutoScrolling.current) {
            setUserScrolled(true);
        } else {
            setUserScrolled(false);
        }

        const isOverflow = target.scrollHeight > target.clientHeight;
        setIsOverflowing(isOverflow);
    }, []);

    const scrollToBottom = useCallback(() => {
        isAutoScrolling.current = true;

        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: "instant" });
            }

            isAutoScrolling.current = false;
        }, 100);
    }, []);

    useEffect(() => {
        if (isAtBottom || !userScrolled) {
            scrollToBottom();
        }
    }, [curConversation, isAtBottom, userScrolled, scrollToBottom]);

    useEffect(() => {
        scrollToBottom();
    }, [scrollToBottom]);

    const switchCurConv = useCallback(
        (item) => {
            setMenuSelectedKey(item.key);
            setCurConversation(item.history);
            setChatHistoryCollapsed(true);
        },
        []
    );

    // useEffect(()=>{
    //     console.log("Chat interface:", formattedCourseData);
    // }, [formattedCourseData])

    const sendChatSSERequest = useCallback(async (newConversation, updateMetrics) => {
        // Prepare metrics variables
        const requestBeginTime = performance.now();
        let compressionStartTime, compressionEndTime, compressionTime = 0;
        let uncompressedPayloadSize = 0;
        let trafficSize = 0;
        let payloadCompressionRatio = 1;
        let requestEndTime, requestTime;

        // The SSE endpoint
        let chat_api = "";
        if (process.env.REACT_APP_PRODUCTION === "1") {
            chat_api = `${server_api_addr}chat/`;
        } else {
            chat_api = `http://localhost:5100/chat`;
        }

        // Retrieve token (if you want to do so in a more robust way, 
        // you can pass the token in as a parameter).
        const tokens = JSON.parse(localStorage.getItem('jwt'));
        const accessToken = tokens?.access;

        // Construct headers
        let headerData = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            // 1. Measure compression time
            compressionStartTime = performance.now();

            const excludeKey = 'chats';

            const neccessaryInformation = Object.entries(activeProfile).reduce((acc, [key, value]) => {
                if (key !== excludeKey) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            let ceab_data = calculateCEABData(scheduleData);
            let ceab_string = "Format:\nitem name: obtained/minimum (outstanding value)\n\n";

            ceab_data = [
                {
                    "key": "Total Accreditation Units",
                    "categories": "Total Accreditation Units",
                    "minimum": 1870,
                    "obtained": 1089.28,
                    "projected": 1144.18,
                    "outstanding": 725.82
                },
                {
                    "key": "Mathematics",
                    "categories": "Mathematics",
                    "minimum": 214.5,
                    "obtained": 255.7,
                    "projected": 255.7,
                    "outstanding": "OK"
                },
                {
                    "key": "Natural Science",
                    "categories": "Natural Science",
                    "minimum": 200,
                    "obtained": 181.08,
                    "projected": 181.08,
                    "outstanding": 18.92
                },
                {
                    "key": "Mathematics and Natural Science Combined",
                    "categories": "Mathematics and Natural Science Combined",
                    "minimum": 462,
                    "obtained": 436.78,
                    "projected": 436.78,
                    "outstanding": 25.22
                },
                {
                    "key": "Engineering Science",
                    "categories": "Engineering Science",
                    "minimum": 247.5,
                    "obtained": 422.4,
                    "projected": 455.34,
                    "outstanding": "OK"
                },
                {
                    "key": "Engineering Design",
                    "categories": "Engineering Design",
                    "minimum": 247.5,
                    "obtained": 140,
                    "projected": 161.96,
                    "outstanding": 85.54
                },
                {
                    "key": "Engineering Science and Engineering Design Combined",
                    "categories": "Engineering Science and Engineering Design Combined",
                    "minimum": 990,
                    "obtained": 844.8,
                    "projected": 910.68,
                    "outstanding": 79.32
                },
                {
                    "key": "Complementary Studies",
                    "categories": "Complementary Studies",
                    "minimum": 240,
                    "obtained": 90.1,
                    "projected": 90.1,
                    "outstanding": 149.9
                }
            ]

            for (const row of ceab_data) {
                ceab_string += `${row.key}: ${row.obtained}/${row.minimum} (${row.outstanding})\n`
            }

            const course_updated_profile = {
                ...neccessaryInformation,
                courses: formattedCourseData
            };

            // It seems to get stale data?
            // console.log("SSE:", formattedCourseData, course_updated_profile);

            // Calculate the uncompressed payload length
            const payloadObj = { chat_hist: newConversation, profile: course_updated_profile, userInfo: currentUserInfo, ceabData: ceab_string };
            uncompressedPayloadSize = JSON.stringify(payloadObj).length;

            const finalData = await compressData(payloadObj, headerData);

            // Calculate traffic size (compressed payload length)
            trafficSize = typeof finalData === 'string'
                ? finalData.length
                : finalData.byteLength;

            // End compression timing
            compressionEndTime = performance.now();
            compressionTime = compressionEndTime - compressionStartTime;

            // Calculate payload compression ratio
            // (Guard against division by 0 in case something goes wrong)
            if (trafficSize !== 0) {
                payloadCompressionRatio = uncompressedPayloadSize / trafficSize;
            }

            // 2. Send request via fetch (SSE is typically just a fetch POST, 
            //    but you can also use EventSource, etc.)

            const response = await fetch(chat_api, {
                method: "POST",
                headers: headerData,
                body: finalData,
            });

            // 3. Measure total request time 
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // Construct success metrics
            let currentRequestMetrics = {
                endpoint: chat_api,
                success: true,

                // Payload metrics
                uncompressed_payload_size: uncompressedPayloadSize,
                payload_size: trafficSize,
                payload_compression_ratio: payloadCompressionRatio,
                compression_time: compressionTime,

                // Request timing
                request_time: requestTime,

                contribute_to_overall: false
            };

            // Update metrics somewhere (this depends on your setup)
            // Example usage:
            // updateMetrics((metrics) => {
            //     metrics.request_history.push(currentRequestMetrics);
            // });

            // 4. Return the Response object so the caller can handle the SSE stream
            return [response, currentRequestMetrics];
        } catch (error) {
            // If there is an error, measure total request time anyway
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // Construct failure metrics
            let currentRequestMetrics = {
                endpoint: chat_api,
                success: false,

                // Payload metrics
                uncompressed_payload_size: uncompressedPayloadSize,
                payload_size: trafficSize,
                payload_compression_ratio: payloadCompressionRatio,
                compression_time: compressionTime,

                // Request timing
                request_time: requestTime,

                // Error info
                error_message: error.message || "Unknown error",
                error: error,

                contribute_to_overall: false
            };

            // Update metrics with error metrics
            updateMetrics(metrics => {
                // Accumulate error counts, times, etc.
                // Push currentRequestMetrics to a history array if desired.
                metrics.request_history.push(currentRequestMetrics);
            });

            // You may also want to handle error-specific behavior, 
            // e.g. setResponseError(error), or navigate to an error page
            // setResponseError(error);
            console.error("Failed to get response from server", error);

            // Return the error so the caller can handle
            // or you could throw the error again if you want the caller
            // to catch it at a higher level
            return error;
        }
    }, [formattedCourseData, activeProfile]);

    useEffect(() => {
        if (activeProfile.chats) {
            const timeGroupChatHistory = groupConversationsByDate(
                activeProfile.chats
            );

            for (const group of timeGroupChatHistory) {
                let hist = group.sessions;
                if (hist.length === 0) {
                    continue;
                }
                setCurConversation(hist[0].history);
                break;
            }

            setGroupedChatHistory(timeGroupChatHistory);

            for (const timeGroup of timeGroupChatHistory) {
                let value = timeGroup["sessions"];

                if (value.length !== 0) {
                    setMenuSelectedKey(value[0].key);
                    break;
                }
            }
        }
    }, [activeProfile]);

    const regenerateResponse = (index) => {
        const newConversation = [...curConversation];
        newConversation.splice(index, 1);

        getChatResponse(newConversation);
    }

    const getChatResponse = useCallback(
        async (newConversation) => {
            setButtonDisabled(true);

            // TODO: Handle the case where the request is not successful(eg. server down， API key disabled, etc.)
            const handle_send_and_update = async (newConversation) => {
                const [response, request_metrics] = await sendChatSSERequest(newConversation, updateMetrics);

                if (response.status !== 200) {
                    console.error("Failed to get response from server", response);

                    // setResponseError(error);

                    navigate('/error', {
                        state: {
                            htmlCode: response.data,
                        },
                    });
                    return [false, -1];
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");

                let assistant = { role: "assistant", content: "" };
                newConversation.push(assistant);
                setCurConversation([...newConversation]);
                setResponseOver(false);

                let num_stream = 0;
                let done = false;
                let use_tool = false;
                let cur_usage = {};

                while (!done) {
                    const { value, done: streamDone } = await reader.read();
                    done = streamDone;

                    num_stream += 1;
                    const chunk = decoder.decode(value, { stream: true });

                    // console.log("Got chunk:", chunk);

                    let splittedChunks = chunk.split("\n");

                    for (const line of splittedChunks) {
                        // await sleep(1)
                        if (line.startsWith("data:")) {
                            const jsonData = line.replace("data: ", "");
                            if (jsonData === "[DONE]") {
                                // setButtonDisabled(false);
                            } else {
                                try {
                                    const parsedJson = JSON.parse(jsonData);

                                    if (parsedJson.type === 'text') {
                                        assistant["content"] += parsedJson.content;
                                    } else if (parsedJson.type === 'tool') {
                                        use_tool = true;
                                        const tool_use_content = parsedJson.content;
                                        const sample = { 'type': 'tool_use', 'id': 'toolu_bdrk_015ww1Xhu8pvyYTMizqVPGdP', 'name': 'top_song', 'input': { 'sign': 'WZPZ' } }

                                        let assistant_response = assistant["content"];

                                        if (typeof (assistant_response) === 'string') {
                                            assistant["content"] = [
                                                {
                                                    "type": "text",
                                                    "text": assistant_response
                                                },
                                                tool_use_content
                                            ]
                                        } else {
                                            assistant["content"] = assistant_response.append(tool_use_content);
                                        }
                                    } else if (parsedJson.type === 'message_stop') {
                                        setResponseOver(true);
                                        setButtonDisabled(false);
                                        cur_usage = parsedJson.usage;
                                    }

                                    setCurConversation([...newConversation]);
                                } catch (e) {
                                    console.error("There is an exception:", e, line);
                                }
                            }
                        }
                    }
                }
                request_metrics['usage'] = cur_usage;
                request_metrics['response'] = assistant['content'];
                updateMetrics((metrics) => {
                    metrics.request_history.push(request_metrics);
                });

                return [use_tool, num_stream];
            }


            while (true) {
                console.log("Send chat", dc(newConversation));
                let [use_tool, num_stream] = await handle_send_and_update(newConversation);

                if (num_stream === -1) {
                    break;
                }

                // console.log("Stream done:", num_stream, dc(newConversation));
                setCurConversation(dc(newConversation));

                // Handle tool requests
                if (use_tool) {
                    let cur_user_tool_response = {
                        "role": "user",
                        "content": []
                    }

                    for (let response of newConversation.at(-1)['content']) {
                        if (response['type'] === "text") {
                            if (response['text'] === "") {
                                response['text'] = "Let me use the following tool to assist you.";
                            }
                            continue
                        } else {
                            console.log("Tool response:", response)

                            let action_result = await performAction(response);
                            cur_user_tool_response['content'].push(action_result);
                            // cur_user_tool_response['content'].push({
                            //     "type": "tool_result",
                            //     "tool_use_id": response["id"],
                            //     "content": "Elemental Hotel"
                            // })
                        }
                    }

                    newConversation.push(cur_user_tool_response);

                    setCurConversation(dc(newConversation));

                    console.log("New conver:", newConversation);
                } else {
                    break
                }
            }

            // Delete new chat key
            if (menuSelectedKey === newChatSession.current?.key) {
                newChatSession.current = null;
            }

            let newAllChat = [];

            for (let chat of activeProfile.chats) {
                if (chat.key === menuSelectedKey) {
                    newAllChat.push({
                        ...chat,
                        history: newConversation,
                        timestamp: (new Date()).toISOString()
                    });
                } else {
                    newAllChat.push(chat);
                }
            }

            console.log('newAllChat: ', newAllChat)

            showServerSaveMessage.current = false;
            setActiveProfile(
                {
                    ...activeProfile,
                    chats: newAllChat
                }
            )

            // TODO: Need a dedicated API to do this
            save_item_to_server('update_profile_chats', {
                key: activeProfile.key,
                chats: newAllChat
            }, true, "", 'Chat successfully saved!', "");
        },
        [menuSelectedKey, formattedCourseData, activeProfile]
    );

    const sendChat = useCallback(() => {
        if (buttonDisabled) {
            return;
        }

        if (currentChatValue.current.length === 0) {
            message.error("Please input to start chatting with the assistant.");
            return;
        }

        let newConversation = [
            ...curConversation,
            {
                role: "user",
                content: currentChatValue.current,
            },
        ];

        setCurConversation(newConversation);

        getChatResponse(newConversation);

        setButtonDisabled(true);
    }, [buttonDisabled, currentChatValue, curConversation, getChatResponse]);

    console.log("Chat Interface Rerender");

    const chatSettingDropdownItems = useMemo(
        () => [
            {
                key: "1",
                label: (
                    <Button
                        icon={chatHistoryCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => {
                            setChatHistoryCollapsed(!chatHistoryCollapsed);
                        }}
                        style={{
                            width: '115px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        History
                    </Button>
                ),
            },
            {
                key: "2",
                label: (
                    <Button
                        icon={<RedoOutlined />}
                        onClick={() => {
                            startNewChat();
                        }}
                        style={{
                            width: '115px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        New Chat
                    </Button>
                ),
            }
        ],
        [chatHistoryCollapsed]
    );

    function getConv(key) {
        for (const chat of activeProfile.chats) {
            if (chat.key === key) {
                return chat;
            }
        }

        return null;
    }

    const startNewChat = useCallback(() => {
        console.log("New chat:")
        console.log(activeProfile)
        console.log(groupedChatHistory)
        const default_conv = [
            {
                role: "assistant",
                content: `Hi ${currentUserInfo.name}, I'm Dream Gary, how can I assist you today?`,
            },
        ];

        // If new chat key is set, then that means that the new chat is not used
        if (newChatSession.current !== null) {
            switchCurConv(newChatSession.current);
            return;
        }

        setCurConversation(default_conv);
        setChatHistoryCollapsed(true);

        const getTodayDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        };

        const cur_id = alphanumerical();
        const today = new Date();
        setMenuSelectedKey(cur_id);

        let newChatData = {
            key: cur_id,
            name: `Chat ${getTodayDate()}`,
            timestamp: today.toISOString(),
            history: default_conv,
        };

        newChatSession.current = newChatData;

        setActiveProfile({
            ...activeProfile,
            chats: [
                newChatData,
                ...activeProfile.chats
            ],
        });
    }, [activeProfile, setActiveProfile]);

    const [showQCArea, setShowQCArea] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (draggingCourse.current) {
                if (draggingCourse.current.source === "index") {
                    setShowQCArea(true);
                } else {
                    setShowQCArea(false);
                }
            } else {
                setShowQCArea(false);
            }
        }, refreshInterval);

        return () => clearInterval(interval);
    }, [draggingCourse]);

    const courseDropQC = useCallback(() => {
        let newQCArray = [
            ...queryCourses,
            formattedCourseData[draggingCourse.current.term_row_index].term_courses[
            draggingCourse.current.term_course_index
            ],
        ];
        let updatedQCArray = [...new Set(newQCArray)];
        setQueryCourses(updatedQCArray);

        if (updatedQCArray.length !== newQCArray.length) {
            message.error("The selected course is already included!");
        }

        draggingCourse.current = null;
    }, [draggingCourse, formattedCourseData, queryCourses, setQueryCourses]);

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "rgb(250, 250, 250)",
            }}
            ref={rootRef}
        >
            <Layout style={{ width: "100%", height: "100%" }}>
                <Sider
                    style={{
                        background: colorBgContainer,
                        textAlign: "left",
                        left: chatHistoryCollapsed
                            ? `-${historyBarWidth}px`
                            : "0px",
                        overflow: "auto",
                    }}
                    width={chatHistoryCollapsed ? 0 : historyBarWidth}
                >
                    <div
                        className="AddNewChatButton"
                        onClick={() => {
                            startNewChat();
                        }}
                    >
                        <PlusCircleOutlined
                            style={{
                                fontSize: "16px",
                            }}
                        />
                        <span
                            style={{
                                marginLeft: "15px",
                                fontSize: "16px",
                                fontFamily: "Gill Sans, sans-serif",
                            }}
                        >
                            Start a new Chat
                        </span>
                    </div>
                    <Menu
                        mode="inline"
                        selectedKeys={menuSelectedKey}
                        items={[
                            {
                                type: "divider",
                                key: "divider",
                            },
                            ...groupedChatHistory
                                .filter((timeGroup) => timeGroup.sessions.length > 0)
                                .map((timeGroup) => ({
                                    key: `grp_${timeGroup.label}`,
                                    label: timeGroup.label,
                                    type: "group",
                                    children: timeGroup.sessions.map((item) => ({
                                        key: item.key,
                                        label: <ChatNameRow item={item} switchCurConv={switchCurConv} newChatSession={newChatSession} />,
                                    })),
                                })),
                        ]}
                    />
                </Sider>
                <Layout style={{ height: "100%" }}>
                    <Content
                        ref={messagesContainerRef}
                        onScroll={handleScroll}
                        style={{
                            height: "100%",
                            minWidth: "350px",
                            maxWidth: "100%",
                            overflowY: "auto",
                            paddingRight: "4px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div ref={messagesStartRef} />
                        <Button
                            style={{
                                position: "sticky",
                                top: "50%",
                                right: "100%",
                                width: "16px",
                                borderRadius: "4px",
                                opacity: collapseButtonVisible ? "100%" : "0%",
                            }}
                            onClick={() => {
                                setChatHistoryCollapsed(!chatHistoryCollapsed);
                            }}
                            onMouseEnter={() => {
                                setCollapseButtonVisible(true);
                            }}
                            icon={
                                chatHistoryCollapsed ? (
                                    <RightOutlined />
                                ) : (
                                    <LeftOutlined />
                                )
                            }
                        />

                        <ChatRows curChatHist={curConversation} curConversation={curConversation} setCurConversation={setCurConversation} generation_over={responseOver} regenerateResponse={regenerateResponse} />
                        <div ref={messagesEndRef} />
                    </Content>
                </Layout>
            </Layout>

            <div
                style={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {showQCArea ? (
                    <div
                        className="QCDropArea"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={courseDropQC}
                    >
                        Drag Course Here to Start Asking Questions
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "5px",
                        }}
                    >
                        {queryCourses.map((item, iterator) => (
                            <div key={`QC-${alphanumerical()}`}>
                                <CloseOutlined
                                    className="closeButton"
                                    onClick={() => {
                                        const newData = [...queryCourses];
                                        newData.splice(iterator, 1);
                                        setQueryCourses(newData);
                                    }}
                                />

                                <div
                                    style={{
                                        width: "130px",
                                        height: "60px",
                                        marginRight: "5px",
                                        color: item["status"] === 0 ? "black" : "#f90",
                                        cursor: "default",
                                    }}
                                    className="CourseCard"
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontFamily: "arial",
                                            textDecoration: "none",
                                            color: item["status"] === 0 ? "black" : "#f90",
                                            fontWeight: "bold",
                                        }}
                                        className="CourseCardFamily CourseCardCode"
                                    >
                                        {item["code"]}
                                    </span>

                                    <div
                                        style={{
                                            fontSize: "7pt",
                                            fontFamily: "arial",
                                            lineHeight: "1",
                                        }}
                                        className="CourseCardFamily CourseCardName"
                                    >
                                        {item["name"]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <ChatInput
                    currentChatValue={currentChatValue}
                    courseDropQC={courseDropQC}
                    sendChat={sendChat}
                    buttonDisabled={buttonDisabled}
                    chatSettingDropdownItems={chatSettingDropdownItems}
                />
                Dream Gary can make mistake. Please check important information.
            </div>
        </div>
    );
};

export default ChatInterface;
