import { Modal, Table, Col, Row, Checkbox, Divider, Tooltip } from "antd";
import { useAppContext } from "./App/AppContext";

const CourseDetailsModal = () => {
    const {
        currentCourseDetails,
        courseInfoModalOpen: open,
        setCourseInfoModalOpen: setOpen,
    } = useAppContext();

    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    
    const courseAUDistributionColumns = [
        {
            title: "Math",
            dataIndex: "MathDistribution",
            key: "Math",
            align: "center",
        },
        {
            title: "NS",
            dataIndex: "NSDistribution",
            key: "NS",
            align: "center",
        },
        {
            title: "CS",
            dataIndex: "CSDistribution",
            key: "CS",
            align: "center",
        },
        {
            title: "ES",
            dataIndex: "ESDistribution",
            key: "ES",
            align: "center",
        },
        {
            title: "ED",
            dataIndex: "EDDistribution",
            key: "ED",
            align: "center",
        },
    ];
        
    const courseDeliveryColumns = [
        {
            title: "Lectures",
            dataIndex: "lectures",
            key: "lectures",
            align: "center",
        },
        {
            title: "Tutorials",
            dataIndex: "tutorials",
            key: "tutorials",
            align: "center",
        },
        { title: "Labs", dataIndex: "labs", key: "labs", align: "center" },
    ];

    const courseCEABAUColumns = [
        { title: "Math", dataIndex: "MathValue", key: "Math", align: "center" },
        { title: "NS", dataIndex: "NSValue", key: "NS", align: "center" },
        { title: "CS", dataIndex: "CSValue", key: "CS", align: "center" },
        { title: "ES", dataIndex: "ESValue", key: "ES", align: "center" },
        { title: "ED", dataIndex: "EDValue", key: "ED", align: "center" },
    ];

    let areaText = "";

    if(!currentCourseDetails.area){
        areaText = "N/A";
    } else {
        let areaList = String(currentCourseDetails.area).split('');
        areaText = areaList.map((area, ite)=>{
            let returnText = "";
            returnText += area;
    
            if(ite !== areaList.length - 1){
                returnText += " and "
            }
            return returnText;
        })
    }

    return (
        <Modal
            title={`${currentCourseDetails.code} - ${currentCourseDetails.name} (${currentCourseDetails.offered})`}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width={750}
            style={{
                top: "5%",
            }}
        >
            <div>
                <p className="courseDetailTitle">
                    <strong>Description:</strong>
                    <span> {currentCourseDetails.description} </span>
                </p>
                <p className="courseDetailTitle">
                    <strong>Prerequisites:</strong>
                    <span> {currentCourseDetails.prerequisites}</span>
                </p>
                <p className="courseDetailTitle">
                    <strong>Co-requisites:</strong>
                    <span> {currentCourseDetails.corequisites}</span>
                </p>
                <p className="courseDetailTitle">
                    <strong>Exclusions:</strong>
                    <span> {currentCourseDetails.exclusions}</span>
                </p>
                <p className="courseDetailTitle">
                    <strong>Credit Weight:</strong>
                    <span> {currentCourseDetails.credit / 100}</span>
                </p>

                <div className="courseDetailTitle">
                    <strong>Course Offer:</strong>
                    <span>
                        <Row>
                            <Col span={4}>
                                <Checkbox checked={currentCourseDetails.fall} disabled>
                                    Fall
                                </Checkbox>
                            </Col>
                            <Col span={4}>
                                <Checkbox checked={currentCourseDetails.winter} disabled>
                                    Winter
                                </Checkbox>
                            </Col>
                        </Row>
                    </span>
                </div>

                <p className="courseDetailTitle">
                    <strong>Course Type:</strong>
                    
                    <span style={{width: 'fit-content'}}> {currentCourseDetails.type}</span>
                </p>
                <p className="courseDetailTitle">
                    <strong>Area of Study:</strong>

                    <span style={{width: 'fit-content'}}> {areaText}</span>
                </p>
                <Divider></Divider>

                <strong>Course Delivery:</strong>
                <Table
                    columns={courseDeliveryColumns}
                    dataSource={[
                        {
                            key: 0,
                            lectures: currentCourseDetails.delivery[0] / 100,
                            tutorials: currentCourseDetails.delivery[1] / 100,
                            labs: currentCourseDetails.delivery[2] / 100,
                        },
                    ]}
                    pagination={false}
                    bordered
                    style={{ marginBottom: "15px" }}
                    size="small"
                />

                <strong>AU Distribution(%):</strong>
                <Table
                    columns={courseAUDistributionColumns}
                    dataSource={[
                        {
                            key: 0,
                            MathDistribution: currentCourseDetails.au_dist[0],
                            NSDistribution: currentCourseDetails.au_dist[1],
                            CSDistribution: currentCourseDetails.au_dist[2],
                            ESDistribution: currentCourseDetails.au_dist[3],
                            EDDistribution: currentCourseDetails.au_dist[4],
                        },
                    ]}
                    pagination={false}
                    bordered
                    style={{ marginBottom: "15px" }}
                    size="small"
                />

                <strong>CEAB AU:</strong>
                <Table
                    columns={courseCEABAUColumns}
                    dataSource={[
                        {
                            key: 0,
                            MathValue: currentCourseDetails.ceab[0] / 100,
                            NSValue: currentCourseDetails.ceab[1] / 100,
                            CSValue: currentCourseDetails.ceab[2] / 100,
                            ESValue: currentCourseDetails.ceab[3] / 100,
                            EDValue: currentCourseDetails.ceab[4] / 100,
                        },
                    ]}
                    pagination={false}
                    bordered
                    style={{ marginBottom: "15px" }}
                    size="small"
                />
            </div>
        </Modal>
    );
};


export default CourseDetailsModal;